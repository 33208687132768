import { COUNTRY_CODE_HK } from './zh-HK/country-code';
import { AUTH_HK } from './zh-HK/authentication';
import { ERROR_HK } from './zh-HK/error';
import { LANDING_HK } from './zh-HK/landing';
import { COMMON_HK } from './zh-HK/common';
import { MENU_HK } from './zh-HK/menu'
import { APPLICATION_HK, APPLICATION_LIST_HK } from './zh-HK/application';
import { FORM_HK } from './zh-HK/form';
import { ADMIN_HK } from './zh-HK/admin';
import { REFERRAL_HK } from './zh-HK/referral';

export default {
  // General
  ...COMMON_HK,
  ...ERROR_HK,
  ...COUNTRY_CODE_HK,

  // Menu
  ...MENU_HK,

  // Page Specific
  ...LANDING_HK,
  ...AUTH_HK,
  ...APPLICATION_LIST_HK,
  ...APPLICATION_HK,
  ...ADMIN_HK,

  // Form
  ...FORM_HK,

  // Referral
  ...REFERRAL_HK,
};
