const ADMIN_HK = Object.freeze({
  'common.approval.approve': 'Approve',
  'common.approval.reject': 'Reject',
  'common.approval.proceed': 'Proceed',
  'common.admin.yes': 'Yes',
  'common.admin.no': 'No',
  'common.admin.unknown': 'Unkown',
  'common.admin.approval.info': 'Confirmation Info',
  'common.admin.approval.stage': 'Review Stage',
  'common.admin.approval.completed': 'Completed',
  'common.admin.approval.message': 'Message',
  'common.admin.approval.user': 'By User',
  'common.admin.approval.readonly': 'Readonly',
  'common.admin.approval.editable': 'Editable',
  'common.admin.approval.readonly.explain': 'Insufficient access, only ({{requiredGroup}}) can modify this page',
  'common.admin.approval.editable.explain': 'You ({{requiredGroup}}) can modify this page',
  'common.admin.confirm.confirmed': 'Confirmed',
  'common.admin.confirm.notconfirmed': 'Pending Confirmation',
  'common.admin.approval.confirm': 'Confirm',
  'common.admin.approval.reject': 'Reject',
  'common.admin.approval.return': 'Return',
  'common.admin.approval.reason': 'Reason:',
  'common.admin.approval.reapprove': 'This review has been confirmed before',
  'common.admin.approval.approve': 'Approve',
  'common.admin.approval.approve.missing.confirm': 'Missing required confirmation',
  'common.admin.approval.approve.proceed': 'Click to approve user',
  'common.admin.approval.approve.confirm': 'This is a irreversiable action\nPlease ensure all information is correct before proceeding',
  'common.admin.approval.confirm.failed': 'Failed to confirm application, please make ensure all required fields are completed.',
  'common.admin.approval.reject.failed': 'Failed to reject application.',
});

export { ADMIN_HK };
