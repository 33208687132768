export const FORM_CN = Object.freeze({
  'form.common.stepn': '步骤{{n}}: {{name}}',
  'form.common.saved': '储存',
  'form.common.savedraft': '储存草稿',
  'form.common.step.previous': '上一步',
  'form.common.step.next': '下一步',
  'form.common.step.submit': '提交',
  'form.common.upload.subscript': '点击或将文件拖拽到这里上传',
  'form.common.upload.onlyfileformat': '上传档案只接受 {{formats}} 格式',
  'form.common.upload.unacceptableformat': undefined,
  'form.common.confirmdelete': '你确定要删除吗？',
  'form.components.datepicker.placeholder': '选择日期',
  'form.common.confirm.convert': 'Confirm converting current application to {{applicationType}}.',
  'form.common.confirm.auto-fill': 'Confirm auto filling current application.',
  //
  'form.basicinfo.nav': '公司基本资料',
  'form.basicinfo.china.companyname': '内地公司名称',
  'form.basicinfo.china.socialcredit': '内地公司统一社会信用代码',
  'form.basicinfo.china.officeaddress': '内地公司办公地址',
  'form.basicinfo.china.employeenumber': '内地公司员工人数',
  'form.basicinfo.china.websiteaddress': '内地公司网页地址',
  'form.basicinfo.china.isofficerental': '内地公司办公室是否为租借?',
  'form.basicinfo.china.officeleasedate': '内地公司办公室租约到期日',
  'form.basicinfo.china.incorporation': '内地公司成立日期',
  'form.basicinfo.hongkong.companyname': '香港公司名称',
  'form.basicinfo.hongkong.businessRegistrationNo': '香港公司商业登记号码',
  'form.basicinfo.hongkong.companyRegistrationNo': '香港公司註册号码',
  'form.basicinfo.hongkong.officeaddress': '香港办公室/注册地址',
  'form.basicinfo.hongkong.employeenumber': '香港公司员工人数',
  'form.basicinfo.hongkong.websiteaddress': '香港公司网页地址',
  'form.basicinfo.hongkong.isofficerental': '香港公司办公室是否为租借?',
  'form.basicinfo.hongkong.officeleasedate': '香港公司办公室租约到期日',
  'form.basicinfo.hongkong.incorporation': '香港公司成立日期',

  //
  'form.uploaddoc.nav': '上载文档',
  'form.uploaddoc.registration.title': ' 营业证明',
  'form.uploaddoc.registration.hk.businessregistration': '商业登记证 (香港公司)',
  'form.uploaddoc.registration.china.mna': '公司章程 (中国公司)',
  'form.uploaddoc.registration.china.lplicense': '企业法人营业执照 (中国公司)',
  'form.uploaddoc.registration.china.officelease': '办公室租约 (中国公司)',
  'form.uploaddoc.registration.hk.financialStatement': '最近两年的经审核财务报表',
  'form.uploaddoc.registration.hk.officelease': '公司商铺的有效租约',
  'form.uploaddoc.persons.title': '法人, 自然人股东和董事身份证明',
  'form.uploaddoc.persons.hk.idpassport': '股东和董事身份证或护照 (香港公司)',
  'form.uploaddoc.persons.china.idpassport': '法人, 自然人股东和董事身份证正反面 (中国公司)',
  'form.uploaddoc.persons.hkchina.address': '法人, 自然人股东和董事地址证明 (香港及中国公司, 3个月内有效)',
  'form.uploaddoc.persons.remarks.1': '如公司拥有多于一名董事，等于或过半数的董事都需要提供文件',
  'form.uploaddoc.persons.remarks.2': '如公司拥有多于一名股东，请提供持股占比等于或多于70%的股东的文件 (包括最大股东)',
  'form.uploaddoc.statement.title': '企业流水证明',
  'form.uploaddoc.statement.bankstatement': '过去6个月银行流水帐单 (香港及中国公司)',
  'form.uploaddoc.statement.thirdparty': '过去6个月第三方支付平台流水记录',
  'form.uploaddoc.agreement.title': '签署同意书',
  'form.uploaddoc.agreement.download': '下载同意书',
  'form.uploaddoc.agreement.crif': '信贷机构同意书 - 道口金科',
  'form.uploaddoc.agreement.tu': '信贷机构同意书 - 环联资讯有限公司',
  'form.uploaddoc.creditreport.title': '信贷报告',
  'form.uploaddoc.creditreport.company': '人民银行企业信用报告 (中国公司)',
  'form.uploaddoc.creditreport.persons': '人民银行个人征信报告 (中国公司股东及董事)',
  'form.uploaddoc.financialreport.title': '财务文件',
  'form.uploaddoc.financialreport.audit': '最近三年的审计报告 (可选)',
  'form.uploaddoc.financialreport.internalledger': '最新的公司内部管理帐 ​​(可选)',
  'form.uploaddoc.financialreport.otherlending': '与其他贷款机构的借贷文件 (如有)',
  'form.uploaddoc.agreement.crif.description':
    '备注:\n北京道口金科科技有限公司我司在中国的信贷合作伙伴。\n简介:\n北京道口金科科技有限公司成立于2018年，孵化于清华大学五道口金融学院互联网金融实验室，是一家专业金融科技公司。道口金科专注中小微企业智慧信贷，通过人工智能与大数据技术，提供全流程信贷解决方案。\n',
  'form.uploaddoc.agreement.crif.helper': '请下载同意书,打印并进行盖章(国内公司公章) 及签署，然后扫描并上载已签署及盖章的同意书。',
  'form.ea.uploaddoc.persons.remarks.2': '如公司拥有多于一名股东，请提供持股占比等于或多于50%的股东的文件 (包括最大股东)',

  //form specially for POS application

  'form.uploaddoc.pos.nav': '上载文档',
  'form.uploaddoc.registration.pos.businessregistration': '商业登记证',
  'form.uploaddoc.statement.pos.bankstatement': '公司银行户口过去六个月的银行月结单',
  'form.uploaddoc.statement.pos.financialstatement': '最近两年的已审核财务报表',
  'form.uploaddoc.registration.pos.financialstatement': '最近两年的已审核财务报表',
  'form.uploaddoc.persons.pos.idpassport': '股东或/及董事的身份证/护照副本',
  'form.uploaddoc.persons.pos.id': '股东或/及董事身份证明 ¹ ²',
  'form.uploaddoc.persons.pos.addressproof': '股东或/及董事于过去三个月的住址证明 ¹ ²',
  'form.uploaddoc.persons.pos.remarks.1': '如公司拥有多于一名董事，一半或以上的董事都需要提供文件',
  'form.uploaddoc.persons.pos.remarks.2': '如公司拥有多于一名股东，请提供持股量50%或以上的股东的文件 ( 包括最大股东)',
  'form.uploaddoc.agreement.pos.helper': `阁下的环聯信贷记录会作为桥彼道批核贷款的参考资料之一(如适用)。
  请提供最大股东所签署的同意书。先下载并列印，签署后上载同意书扫描档至本表格。`,
  'form.uploaddoc.agreement.pos.tu.description': '1. 桥彼道有机会以电邮要求其他股东/董事签署同意书以便查询相关的信贷记录。',
  'form.uploaddoc.statement.pos.rentproof': '最新的店铺租金缴纳證明',
  'form.uploaddoc.statement.pos.generalrestaurantlicence': '普通食肆牌照 (如贵公司经营食肆，请按情况提供)',
  'form.uploaddoc.statement.pos.lightrefreshmentlicence': '小食食肆牌照 (如贵公司经营食肆，请按情况提供)',
  'form.uploaddoc.statement.pos.liquorlicence': '酒牌 (如贵公司有贩卖酒精，请提供)',
  'form.basicinfo.pos.bankname': '银行名称',
  'form.basicinfo.pos.bankname.gobackselect': '重设',
  'form.basicinfo.pos.bankname.pleaseselect': '请选择银行',
  'form.basicinfo.pos.bankaccount': '银行户口号码',
  'form.basicinfo.pos.beneficiary': '银行户口持有人名称',

  'form.basicinfo.pos.companyname': '公司注册名称',
  'form.basicinfo.pos.businessRegistrationNo': '公司商业登记号码',
  'form.basicinfo.pos.officeaddress': '办公室/注册地址',
  'form.basicinfo.pos.posincomeratio': 'POS卡机收入占店铺总收入比例（%）',
  'form.basicinfo.pos.loanamount': '需求贷款金额(HKD)',
  'form.basicinfo.pos.loanusage': '贷款用途',
  'form.confirm.pos.contract': `{{companyNameHongkong}}（以下合称“ 本公司”) 向Qupital Limited （“桥彼道”) 确认并保证本贷款申请(” 本申请“)内所载资料均是真实无讹、准确并完整。 本公司同意桥彼道有权随时向任何第叁者索取有关本公司的资料，包括但不限于向信贷资料服务机构进行查阅。

  本公司确认提交本申请之人士已获得本公司授权进行一切与本申请有关之事项。

  本公司谨此声明本公司具偿债能力，现时没有涉及任何破产、诉讼或行政程序，或此等待决事项，或按本公司在作出一切合理查询后所知，威胁会展开该等事项。 本公司声明本公司并无与其他贷款人作出任何对本公司偿还桥彼道就此申请批出的贷款(如有) 构成阻碍的安排。 此外，本公司确认及同意在本公司完全偿还桥彼道贷款之前，不会自愿性申请破产。

  本公司明白申请贷款批核乃参考本公司提供之申请资料及文件，贷款审批结果最终由桥彼道决定，而桥彼道没有责任就拒绝此贷款申请提供任何原因。 桥彼道对本申请及由其直接或间接引起的一切的争议拥有最终决定权。 本公司明白并同意在收到桥彼道通知成功获批结果后，本公司须确认接受桥彼道的条款及细则，当中包括《桥彼道平台条款及细则》(“平台条款”)。 本公司确认已详阅并明白平台条款，并同意受平台条款所约束。

  本公司确认已经详阅并明白附载于本申请页面的桥彼道收集个人资料声明（“个人资料声明”），并且同意受桥彼道私隐政策约束，包括但不限于，桥彼道按个人资料声明及私隐政策收集、使用及披露本公司的个人资料。

  本公司明白为向本公司提供服务，桥彼道可能把本申请中的个人资料转移至香港境外，交予桥彼道位于中国的集团公司及 / 或桥彼道的代理人(包括, 但不限于, 桥彼道聘用来追讨桥彼道的客户过期的贷款的第三方公司)。 该地区可能没有与《个人资料(私隐)条例》(香港法例第四八六章)大体上相似或达致与此条例的目的相同目的之法律，亦即是说， 本申请中的个人资料未必可以获得与在香港相同或类似程度的保障。

  本公司确认本公司并未与桥彼道以外的任何人(“第三方”)因促致、洽商、取得或申请此笔贷款，及 / 或因担保或保证此笔贷款的偿还，或由于与该等事务有关，达成或签订任何协议（本公司与本公司委任的律师纯粹为提供法律服务而达成或签订的协议除外）。 本公司明白此确认的目的是确保本公司不会被任何第三方另行征收费用。

  本公司根据《电子交易条例》(香港法例第五五叁章)第十五条同意桥彼道以电子纪录或以电子方式向本公司提供借香港法律(包括但不限于放债人条例(香港法例第一六叁章)) 须以书面形式提供之资料。`,
  'form.confirm.pos.authorization': ' 本人({{authorizedPersonName}}) 确认已获得 {{companyNameHongkong}} 的授权代其向桥彼道申请本贷款。',
  'form.confirm.pos.authorizedpersonname': '获授权人中文/英文全名',
  'form.confirm.pos.authorizedpersonsign': '获授权人签署',
  'form.confirm.pos.authorizedpersonid': '获授权人香港身份证号码',
  'form.confirm.pos.authorizedate': '日期',
  'form.confirm.pos.authorizedpersonname.placeholder': '[获授权人中文/英文全名]',
  'form.director.pos.form.companyid': '香港公司商业登记号码',
  'form.uploaddoc.financialstatement.pos.remarks.1':'如公司拥有多于一个商铺，请提供所有商铺的有效租约',
  'form.shareholder.pos.form.id': '香港身份证号码',
  'form.director.pos.form.id': '香港身份证号码',
  'form.uploaddoc.registration.pos.hk.officelease': '公司商铺的有效租约 ¹',

  //
  'form.persons.nav': '法人, 股东及董事资料',

  'form.persons.mainland.title': '内地公司资料',
  'form.persons.mainland.legalperson.title': '内地公司法人资料',
  'form.persons.mainland.legalperson.name': '內地公司法定代表人名称',
  'form.persons.mainland.legalperson.id': '身份证号码',
  'form.persons.mainland.legalperson.nationality': '国籍',
  'form.persons.mainland.legalperson.email': '电邮',
  'form.persons.mainland.legalperson.phonenumber': '手提电话号码',
  'form.persons.mainland.legalperson.homeaddress': '住宅地址',

  'form.shareholder.hongkong.title': '香港公司股东资料',
  'form.shareholder.mainland.title': '内地公司股东资料',
  'form.shareholder.add': '添加股东',
  'form.shareholder.new': '新股东',
  'form.shareholder.form.type': '类型',
  'form.shareholder.form.type.person': '个人',
  'form.shareholder.form.type.company': '公司',
  'form.shareholder.form.name': '名称/姓名(中文)',
  'form.shareholder.form.englishname': '名称/姓名(英文)',
  'form.shareholder.form.id': '身份证号码',
  'form.shareholder.form.nationality': '国籍',
  'form.shareholder.form.phonenumber': '手提电话号码',
  'form.shareholder.form.position': '职位',
  'form.shareholder.form.email': '电邮',
  'form.shareholder.form.shareratio': '股权比例(%)',
  'form.shareholder.form.homeaddress': '住宅地址',
  'form.shareholder.form.enghomeaddress': '住宅地址(英文)',
  'form.shareholder.form.companyid': '香港公司商业登记号码/内地公司统一社会信用代码',
  'form.shareholder.form.officeaddress': '办公地址(中文)',
  'form.shareholder.form.engofficeaddress': '办公地址(英文)',
  'form.shareholder.form.registeredaddress': '注册地址',
  'form.shareholder.form.registeredcountry': '注册国家',
  'error.input.shareholder.at.least': '请提供至少{{value}}个股东资料',

  'form.director.hongkong.title': '香港公司董事资料',
  'form.director.mainland.title': '内地公司董事资料',
  'form.director.add': '添加董事',
  'form.director.new': '新董事',
  'form.director.form.type': '类型',
  'form.director.form.type.person': '个人',
  'form.director.form.type.company': '公司',
  'form.director.form.name': '名称/姓名(中文)',
  'form.director.form.englishname': '名称/姓名(英文)',
  'form.director.form.id': '身份证号码',
  'form.director.form.nationality': '国籍',
  'form.director.form.phonenumber': '手提电话号码',
  'form.director.form.position': '职位',
  'form.director.form.email': '电邮',
  'form.director.form.homeaddress': '住宅地址(中文)',
  'form.director.form.englishhomeaddress': '住宅地址(英文)',
  'form.director.form.companyid': '香港公司商业登记号码/内地公司统一社会信用代码',
  'form.director.form.officeaddress': '办公地址(中文)',
  'form.director.form.engofficeaddress': '办公地址(英文)',
  'error.input.director.at.least': '请提供至少{{value}}个董事资料',

  //
  'form.contact.nav': '联络人资料',
  'form.contact.title': '新联络人',
  'form.contact.add': '添加联络人',
  'form.contact.new': '新联络人',
  'form.contact.form.name': '名称',
  'form.contact.form.position': '职位',
  'form.contact.form.phonenumber': '手提电话号码',
  'form.contact.form.email': '电邮',

  //
  'form.financial.add': '添加条目',
  'form.financial.nav': '财务资料',
  'form.financial.notice': '请在步骤二输入公司成立日期以便完成此页。\n如你没有利润表或资产负债表, 请用最近12个月的公司营运状况来填写。',
  'form.financial.mainland.title': '內地公司',
  'form.financial.hongkong.title': '香港公司',
  'form.financial.card.title': '{{year.from}} - {{year.to}}',
  'form.financial.annual': '{{year, MM/YYYY}}',
  'form.financial.year': '年度',
  'form.financial.form.yearstart': '财务开始年度',
  'form.financial.form.yearend': '财务完结年度',
  'form.financial.form.currency': '货币',
  'form.financial.form.profitnloss': '利润表',
  'form.financial.form.operatingincome': '营业收入',
  'form.financial.form.operatingcost': '营业成本',
  'form.financial.form.expenditure': '折旧及摊销费用',
  'form.financial.form.interestincome': '利息支出',
  'form.financial.form.financialfees': '财务费用',
  'form.financial.form.operatingprofit': '营业利润',
  'form.financial.form.pretaxprofit': '税前利润',
  'form.financial.form.netprofit': '净利润',
  'form.financial.form.balancesheet': '资产负债表',
  'form.financial.form.asset': '资产',
  'form.financial.form.liability': '负债',
  'form.financial.form.cash': '货币资金',
  'form.financial.form.receivable': '应收帐款',
  'form.financial.form.inventory': '存货',
  'form.financial.form.currentasset': '流动资产合计',
  'form.financial.form.currentliability': '流动负债合计',
  'form.financial.form.loan': '负债合计',
  'form.financial.form.amountowned': '借款总额',
  'form.financial.form.totalasset': '资产总计',

  //
  'form.liability.nav': '其他授信和未结清借款',
  'form.liability.has': '本公司沒有未結清的貸款和任何授信額度', // Update to spec
  'form.liability.add': '添加',
  'form.liability.form.entity': '借款主体名称',
  'form.liability.form.type': '贷款方名称',
  'form.liability.form.type.description': '銀行 / 财务机构 / 第三方支付平台 / 網上借款平台 / 電商平台 / 其它',
  'form.liability.form.currency': '货币',
  'form.liability.form.amount': '额度',
  'form.liability.form.own': '截至申请日的未结清借款',
  'form.liability.form.date': '申报日期',
  'form.liability.validate.no.loan': '如公司沒有未結清的貸款请选择此项目',
  'form.liability.validate.no.empty.entry': '如公司沒有未結清的貸款, 请删除所有空条目',
  'form.liability.explain': '如贵司有任何未结清贷款或其他额度，请在本页面上进行申报。如没有任何未结清的贷款或其他授信额度，请在以下方格中打勾',

  //
  'form.ecomshop.nav': '店舖资料',
  'form.ecomshop.description':
    '我们会以贵司月均销售额的三到四成作为审批额度的基础。 如贵司的月均销售为三十万美元，我司的额度审批将会以九万美元至十二万美元作为基础。最终额度会因应贵司的运营表现进行调整。 如你没有利润表或资产负债表, 请用最近12个月的公司营运状况来填写。',
  'form.ecomshop.add': '添加店舖',
  'form.ecomshop.steps': '步骤',
  'form.ecomshop.amazon.title': '亚马逊店舖',
  'form.ecomshop.amazon.sellerid': '卖家ID',
  'form.ecomshop.amazon.token': 'MWS授权码',
  'form.ecomshop.amazon.region': '区域',
  'form.ecomshop.thirdparty': '第三方支付平台',
  'form.ecomshop.thirdparty.placeholder': '请选择或填写',
  'form.ecomshop.amazon.specify': '请注明',
  'form.ecomshop.common.bankid': '店铺对应之收款平台帐户银行户口号码',
  'form.ecomshop.amazon.shopname': '亚马逊店铺名称',
  'form.ecomshop.ebay.title': 'eBay店舖',
  'form.ecomshop.ebay.shopname': 'eBay店铺名称',
  'form.ecomshop.ebay.thirdparty': '第三方支付平台',
  'form.ecomshop.ebay.specify': '请注明',
  'form.ecomshop.ebay.bankid': '已连接店铺对应之收款平台 账户银行户口号码',
  'form.ecomshop.authorize': '授权',
  'form.ecomshop.authorize.inprogress': '授权进行中',
  'form.ecomshop.authorize.success': '授权成功',
  'form.ecomshop.authorize.failed': '授权失败',
  'form.ecomshop.ebay.paypal': '已对接的PayPal邮箱',
  'form.ecomshop.deleted': '商店已删除',
  'form.ecomshop.must.have.shop': '请连接最少一个电商店铺',
  'form.ecomshop.start.enter': '开始输入',
  'form.ecomshop.shopee.title': 'Shopee店舖',
  'form.ecomshop.shopee.shopname': 'Shopee店铺名称',
  'form.ecomshop.lazada.title': 'Lazada店舖',
  'form.ecomshop.lazada.shopname': 'Lazada店铺名称',
  'form.ecomshop.tmall.is': '本公司在Tmall上进行营运， 并希望对接Tmall的店铺以进行申请流程。',
  'form.ecomshop.tiktok.is': '本公司在Tiktok上进行营运， 并希望对接Tiktok的店铺以进行申请流程。',
  'form.ecomshop.mercado.is': '本公司在Mercado上进行营运， 并希望对接Mercado的店铺以进行申请流程。',
  'form.ecomshop.wish.title': 'Wish店舖',
  'form.ecomshop.wish.shopname': 'Wish店铺名称',
  'form.ecomshop.shopify.title': 'Shopify店舖',
  'form.ecomshop.shopify.shopname': 'Shopify店铺名称',
  'form.ecomshop.shopline.title': 'Shopline店舖',
  'form.ecomshop.shopline.shopname': 'Shopline店铺名称',
  'form.ecomshop.shoplazza.title': 'Shoplazza店舖',
  'form.ecomshop.shoplazza.shopname': 'Shoplazza店铺名称',
  //
  'form.confirm.nav': '确认申请',
  'form.confirm.estimatedamount': '预计信用额度',
  'form.confirm.discountrate': '每笔贷款利息',
  'form.confirm.discountrate.prefix': '约',
  'form.confirm.discountrate.subfix': '% /日',
  'form.confirm.earndisclaimer': '桥彼道将就每笔贷款收取{{range}}的手续费（根据贷款周期而定，已扣除适用手续费回赠）。',
  'form.ea.confirm.earndisclaimer': '以上利息已包含手续费。',
  'form.confirm.feedisclaimer': '以上信息不构成桥彼道有限公司对任何人作出的任何陈述，保证，承诺或要约。 如有任何争议，桥彼道有限公司保留一切最终决定权。',
  'form.confirm.confirm': '本公司明白及同意以上条款',
  'form.confirm.declaration':
    '本公司已详阅并同意以上声明真实及正确。本公司同意受以上声明所列的所有条款约束。如本公司声明不实，本公司同意承担所有法律责任以及向桥彼道赔偿一切直接或间接引起的损失。',
  'form.confirm.contract': `{{companyNameHongkong}}以及{{companyNameMainland}} （”本公司“) 向Qupital Limited （”桥彼道“) 确认并保证本贷款申请(“本申请”)内所载资料均是真实无讹、准确并完整。本公司同意桥彼道有权随时向任何第叁者索取有关本公司的资料，包括但不限于向信贷资料服务机构(包括环联资讯有限公司)以及支付机构进行查阅。本公司同意，桥彼道有机会以电邮或其他方式要求本公司任何股东或董事签署相关同意书以便查询相关的信贷记录。

  本公司确认提交本申请之人士已获得本公司授权进行一切与本申请有关之事项。

  本公司谨此声明本公司具偿债能力，现时没有涉及任何破产、诉讼或行政程序，或此等待决事项，或按本公司在作出一切合理查询后所知，威胁会展开该等事项。本公司声明本公司并无与其他贷款人作出任何对本公司偿还桥彼道就此申请批出的贷款 (如有) 构成阻碍的安排。此外，本公司确认及同意在本公司完全偿还桥彼道贷款之前，不会自愿性申请清盘。

  本公司明白申请贷款批核乃参考本公司提供之申请资料及文件，贷款审批结果最终由桥彼道决定，而桥彼道没有责任就拒绝此贷款申请提供任何原因。桥彼道对本申请及由其直接或间接引起的一切的争议拥有最终决定权。本公司明白并同意在收到桥彼道通知成功获批结果后，本公司须确认接受桥彼道的条款及细则，当中包括《桥彼道平台条款及细则 (Key Terms and Conditions for Credit Facilities)》。

  本公司确认已经详阅并明白附载于本申请页面的桥彼道收集个人资料声明（“个人资料声明”），并且同意受桥彼道私隐政策约束，包括但不限于，桥彼道按个人资料声明及私隐政策收集、使用及披露本公司股东，董事，员工或其他相关人士的个人资料。

  本公司明白为向本公司提供服务，桥彼道可能把本申请中的个人资料转移至香港境外，交予桥彼道位于中国大陆的集团公司及/或桥彼道的代理人(包括, 但不限于, 桥彼道聘用来追讨桥彼道的客户过期的贷款的第三方公司)。该地区可能没有与《个人资料(私隐)条例》(香港法例第四八六章)大体上相似或达致与此条例的目的相同目的之法律，亦即是说，本申请中的个人资料未必可以获得与在香港相同或类似程度的保障。

  本公司根据《电子交易条例》(香港法例第五五叁章)第十五条同意桥彼道以电子纪录或以电子方式向本公司提供借香港法律(包括但不限于放债人条例(香港法例第一六叁章)) 须以书面形式提供之资料。`,
  'form.confirm.hongkongcompany.placeholder': '[请输入香港公司名称]',
  'form.confirm.mainlandcompany.placeholder': '[请输入大陆公司名称]',
  'form.confirm.must.all.completed': '请确保所有表格已经填妥，方可提交。',
  'form.confirm.limit.placeholder': '请连接店铺以估算信用额度',
  'form.confirm.personal-info-collection-statement': '按此阅读收集个人资料声明',
  //
  'form.thirdparty._LIANLIANPAY': '连连支付 (LianLian Pay)',
  'form.thirdparty._PINGPONG': 'PingPong',
  'form.thirdparty._PAYONEER': '派安盈 (Payoneer)',
  'form.thirdparty._SKYEE': '收款易 (Skyee)',
  'form.thirdparty._AIRWALLEX': '空中云汇 (Airwallex)',
  'form.thirdparty._WORLDFIRST': '万里汇 (WorldFirst)',
  'form.thirdparty._ONERWAY': '万唯 (OnerWay)',
  'form.thirdparty._SUNRATE': '寻汇 (Sunrate)',
  'form.thirdparty._BEEPAY': 'Beepay',
  'form.thirdparty._HUIFU': '汇付天下',
  'form.thirdparty._ASIALINK': '亚联',
  'form.thirdparty._CURRENXIE': 'Currenxie',
  'form.thirdparty._IPAYLINKS': 'iPayLinks',
  'form.thirdparty._OFX': 'OFX',
  'form.thirdparty._VCAN': 'Vcan',

  'form.amazon.region.NA': '北美',
  'form.amazon.region.EU': '欧洲',
  'form.amazon.region.FE': '日本 / 澳洲',

  'form.ecomshop.notice': `我们会以贵司月均销售额的三到四成作为审批额度的基础。
如贵司的月均销售为三十万美元，我司的额度审批将会以九万美元至十二万美元作为基础。最终额度会因应贵司的运营表现进行调整。
本公司知悉及授权桥彼道有限公司获取及使用本公司在电商平台上的店铺资料和数据作分析之用。`,

  'form.credit.nav': 'Credit Related Information',
  'form.credit.partner': 'Partner',
  'form.credit.partnername': 'Partner Name',
  'form.credit.customid': "Applicant's Customer ID in Partner",
  'form.credit.rating': 'Quipital Rating',
  'form.credit.obligor': 'Obligor / Platform Name / POS',
  'form.credit.percentage': 'Obligor Relationship: Qupital Advance Percentage (%)',
  'form.credit.bullet-credit-limit': 'Bullet Credit Limit (USD)',
  'form.credit.instalment-credit-limit': 'Instalment Credit Limit (USD)',
  'form.credit.internallimit': 'Internal Credit Limit',
  'form.credit.internallimitExpiryDate': 'Internal Credit Limit Expiry Date',
  'form.credit.creditrating': 'Seller Credit Rating',
  'form.credit.industry': 'Seller Industry',
  'form.credit.partner.placeholder': 'No Partnership',
  'form.credit.industry.placeholder': 'No Industry',
  'form.credit.maxDebtDuration': 'Max. Debt Duration',
  'form.credit.electronic-document': 'Received clear and complete electronic scanned documents',
  'form.credit.original-document': 'Received original documents',

  'form.sales.nav': 'Sales Related Information',
  'form.sales.partner': 'Partner',
  'form.sales.partnername': 'Partner Name',
  'form.sales.customid': "Applicant's Customer ID in Partner",
  'form.sales.sales': 'Sales',
  'form.sales.salesname': 'Sales Name',
  'form.sales.salescommission': 'Sales Commission Decimal (%)',
  'form.sales.saleexpiry': 'Sales Commission Expiry Date',
  'form.sales.feestructure': 'Fee Schedule',
  'form.sales.obligor': 'Obligor / Platform Name / POS',
  'form.sales.lowestPrice': 'Lowest Price (%)',
  'form.sales.actualPrice': 'Actual Price (%)',
  'form.sales.percentage': 'Obligor Relationship: Funder Fee (%)',
  'form.sales.platformfee': 'Obligor Relationship: Platform Fee Rate (%)',
  'form.sales.credit-enhancement': 'Credit Enhancement',
  'form.sales.feestructure.add': '添加',

  'error.input.array.invalid.format': '格式错误',
  'error.input.array.not.greater.than': '请提供最多{{value}}条记录',
  'error.input.array.not.less.than': '请提供最少{{value}}条记录',
  'error.input.boolean.invalid.format': '请重新选择',
  'error.input.date.not.later.than.today': '不可遲于今天',
  'error.input.date.must.be.later.than.today': '不可早于今天',
  'error.input.email.invalid.format': '格式错误',
  'error.input.enum.currency.invalid': '格式错误',
  'error.input.enum.director-type.invalid': '格式错误',
  'error.input.must.be.applicationstage': '格式错误',
  'error.input.must.be.atleast': '不可小于{{value}}',
  'error.input.must.be.currency': '格式错误',
  'error.input.must.be.date': '格式错误',
  // 'error.input.must.be.defined': ''
  'error.input.must.be.email': '格式错误',
  'error.input.must.be.number': '格式错误',
  'error.input.must.be.number.with.decimal': '格式错误, 最多{{decimal}}位小数',
  'error.input.must.be.phonenumber': '格式错误',
  'error.input.must.be.shareholdertype': '格式错误',
  'error.input.must.be.url': '格式错误',
  'error.input.must.be.usertype': '格式错误',
  'error.input.number.not.less.than': '不可小于{{value}}',
  'error.input.number.not.more.than': '不可大于{{value}}',
  'error.input.string.invalid.format': '格式错误',
  'error.input.uuid.invalid.format': '格式错误',
  'error.input.number.must.be.positive': '必须为正数',
  'error.input.must.consent': '请同意本项',
  'error.input.shareholder.must.be.sum.100': '股权比例总和应大于70％',
  'error.input.financial.must.have.n.years': '请输入{{n}}年份财务资料',
  'error.input.credit-limit.must.eq.sum-of-sub-credit-limit': '总信用额度必须等于子信用额度之和',
  'error.ea.input.shareholder.must.be.sum.100': '股权比例总和应大于50％',


  'form.common.must.enter': '必须提供',
});
