const AUTH_HK = Object.freeze({
  'login.title': '登錄<br/> 你的橋彼道<br/> 帳戶',
  'login.subtitle': '歡迎回來！ 請登入你的帳戶',
  'login.login': '登錄',
  'login.password': '密碼',
  'login.forgot-password': '忘記密碼',
  'login.register': '註冊帳戶',
  'forgot-password.title': '忘記密碼',
  'forgot-password.subtitle': '重設密碼的電郵會直接發送到你的電郵位址',
  'forgot-password.completed': '電郵經已發送到你的電郵位址, 請查收',
  'forgot-password.go.back.to.login': '返回登錄頁面',
  'registration.title': '註冊帳戶',
  'registration.subtitle': '請填寫資料以註冊小橋貸帳戶',
  'registration.password': '密碼',
  'registration.confirm.password': '確認密碼',
  'registration.lastname': '姓氏',
  'registration.firstname': '名字',
  'registration.job.position': '職位',
  'registration.phone.number': '電話號碼',
  'registration.register': '註冊',
  'registration.have.account.already': '使用已有帳戶登錄',
  'reset-forgot-password.title': '重設密碼',
  'reset-forgot-password.subtitle': '請輸入新密碼',
  'reset-forgot-password.password': '新密碼',
  'reset-forgot-password.confirm.password': '確認新密碼',
  'reset-forgot-password.reset': '重設密碼',
  'reset-forgot-password.completed': '你的密碼經已被重設, 請重新登錄',
  'reset-forgot.password.go.back.to.login': '返回登錄頁面',
});

export { AUTH_HK };

