const COUNTRY_CODE_CN = Object.freeze({
  AND: '安道尔',
  ARE: '阿拉伯联合酋长国',
  AFG: '阿富汗',
  ATG: '安提瓜和巴布达',
  AIA: '安圭拉',
  ALB: '阿尔巴尼亚',
  ARM: '亚美尼亚',
  AGO: '安哥拉',
  ATA: '南极洲',
  ARG: '阿根廷',
  ASM: '美属萨摩亚',
  AUT: '奥地利',
  AUS: '澳大利亚',
  ABW: '阿鲁巴',
  ALA: '奥兰群岛',
  AZE: '阿塞拜疆',
  BIH: '波斯尼亚和黑山共和国',
  BRB: '巴巴多斯',
  BGD: '孟加拉',
  BEL: '比利时',
  BFA: '布基纳法索',
  BGR: '保加利亚',
  BHR: '巴林',
  BDI: '布隆迪',
  BEN: '贝宁',
  BLM: '圣巴泰勒米',
  BMU: '百慕大',
  BRN: '文莱',
  BOL: '玻利维亚',
  BES: '博奈尔岛、圣尤斯达蒂斯和萨巴',
  BRA: '巴西',
  BHS: '巴哈马',
  BTN: '不丹',
  BVT: '布维特岛',
  BWA: '博茨瓦纳',
  BLR: '白俄罗斯',
  BLZ: '伯利兹',
  CAN: '加拿大',
  CCK: '科科斯群岛',
  COD: '扎伊尔',
  CAF: '中非共和国',
  COG: '刚果',
  CHE: '瑞士',
  CIV: '象牙海岸',
  COK: '库克群岛',
  CHL: '智利',
  CMR: '喀麦隆',
  CHN: '中国',
  COL: '哥伦比亚',
  CRI: '哥斯达黎加',
  CUB: '古巴',
  CPV: '佛得角',
  CUW: '库拉索',
  CXR: '圣诞岛',
  CYP: '塞浦路斯',
  CZE: '捷克共和国',
  DEU: '德国',
  DJI: '吉布提',
  DNK: '丹麦',
  DMA: '多米尼加',
  DOM: '多米尼加共和国',
  DZA: '阿尔及利亚',
  ECU: '厄瓜多尔',
  EST: '爱沙尼亚',
  EGY: '埃及',
  ESH: '西撒哈拉',
  ERI: '厄立特里亚',
  ESP: '西班牙',
  ETH: '埃塞俄比亚',
  FIN: '芬兰',
  FJI: '斐济',
  FLK: '福克兰群岛',
  FSM: '密克罗尼西亚',
  FRO: '法罗群岛',
  FRA: '法国',
  GAB: '加蓬',
  GBR: '英国',
  GRD: '格林纳达',
  GEO: '格鲁吉亚',
  GUF: '法属圭亚那',
  GGY: '格恩西岛',
  GHA: '加纳',
  GIB: '直布罗陀',
  GRL: '格陵兰',
  GMB: '冈比亚',
  GIN: '几内亚',
  GLP: '瓜德罗普岛',
  GNQ: '赤道几内亚',
  GRC: '希腊',
  SGS: '南乔治亚岛和南桑威齐群岛',
  GTM: '危地马拉',
  GUM: '关岛',
  GNB: '几内亚比绍',
  GUY: '圭亚那',
  HKG: '中国香港',
  HMD: '赫德与麦克唐纳群岛',
  HND: '洪都拉斯',
  HRV: '克罗地亚',
  HTI: '海地',
  HUN: '匈牙利',
  IDN: '印度尼西亚',
  IRL: '爱尔兰',
  ISR: '以色列',
  IMN: '曼岛',
  IND: '印度',
  IOT: '英属印度洋领地',
  IRQ: '伊拉克',
  IRN: '伊朗伊斯兰共和国',
  ISL: '冰岛',
  ITA: '意大利',
  JEY: '泽西岛',
  JAM: '牙买加',
  JOR: '约旦',
  JPN: '日本',
  KEN: '肯尼亚',
  KGZ: '吉尔吉克斯坦',
  KHM: '柬埔寨',
  KIR: '基里巴斯',
  COM: '科摩罗',
  KNA: '圣基茨和尼维斯',
  PRK: '朝鲜民主共和国',
  KOR: '大韩民国',
  KWT: '科威特',
  CYM: '开曼群岛',
  KAZ: '哈萨克斯坦',
  LAO: '老挝人民民主共和国',
  LBN: '黎巴嫩',
  LCA: '圣卢西亚',
  LIE: '列支敦士登',
  LKA: '斯里兰卡',
  LBR: '利比里亚',
  LSO: '莱索托',
  LTU: '立陶宛',
  LUX: '卢森堡',
  LVA: '拉脱维亚',
  LBY: '阿拉伯利比亚民众国',
  MAR: '摩洛哥',
  MCO: '摩纳哥',
  MDA: '摩尔多瓦共和国',
  MNE: '黑山共和国',
  MAF: '圣马丁',
  MDG: '马达加斯加',
  MHL: '马绍尔群岛',
  MKD: '前南斯拉夫马其顿共和国',
  MLI: '马里',
  MMR: '缅甸',
  MNG: '蒙古',
  MAC: '中国澳门',
  MNP: '北马里亚纳群岛',
  MTQ: '马提尼克群岛',
  MRT: '毛里塔尼亚',
  MSR: '蒙塞拉特群岛',
  MLT: '马耳他',
  MUS: '毛里求斯',
  MDV: '马尔代夫',
  MWI: '马拉维',
  MEX: '墨西哥',
  MYS: '马来西亚',
  MOZ: '莫桑比克',
  NAM: '纳米比亚',
  NCL: '新喀里多尼亚',
  NER: '尼日尔',
  NFK: '诺福克岛',
  NGA: '尼日利亚',
  NIC: '尼加拉瓜',
  NLD: '荷兰',
  NOR: '挪威',
  NPL: '尼泊尔',
  NRU: '瑙鲁',
  NIU: '纽埃',
  NZL: '新西兰',
  OMN: '阿曼',
  PAN: '巴拿马',
  PER: '秘鲁',
  PYF: '法属波利尼西亚',
  PNG: '巴布亚新几内亚',
  PHL: '菲律宾',
  PAK: '巴基斯坦',
  POL: '波兰',
  SPM: '圣皮埃尔和密克隆',
  PCN: '皮特凯恩群岛',
  PRI: '波多黎各',
  PSE: '巴勒斯坦领土',
  PRT: '葡萄牙',
  PLW: '帕劳',
  PRY: '巴拉圭',
  QAT: '卡塔尔',
  REU: '留尼汪',
  ROU: '罗马尼亚',
  SRB: '塞尔维亚',
  RUS: '俄罗斯',
  RWA: '卢旺达',
  SAU: '沙特阿拉伯',
  SLB: '所罗门群岛',
  SYC: '塞舌尔群岛',
  SDN: '苏丹',
  SWE: '瑞典',
  SGP: '新加坡',
  SHN: '圣赫勒拿',
  SVN: '斯洛文尼亚',
  SJM: '斯瓦尔巴特和扬马延',
  SVK: '斯洛伐克共和国',
  SLE: '塞拉利昂',
  SMR: '圣马力诺',
  SEN: '塞内加尔',
  SOM: '索马里',
  SUR: '苏里南',
  SSD: '南苏丹',
  STP: '圣多美和普林西比',
  SLV: '萨尔瓦多',
  SXM: '圣马丁岛',
  SYR: '叙利亚',
  SWZ: '斯威士兰',
  TCA: '特克斯和凯科斯群岛',
  TCD: '乍得',
  ATF: '法属南部领土',
  TGO: '多哥',
  THA: '泰国',
  TJK: '塔吉克斯坦',
  TKL: '托克劳',
  TLS: '东帝汶',
  TKM: '土库曼斯坦',
  TUN: '突尼斯',
  TON: '汤加',
  TUR: '土耳其',
  TTO: '特立尼达和多巴哥',
  TUV: '图瓦卢',
  TWN: '中国台湾',
  TZA: '坦桑尼亚',
  UKR: '乌克兰',
  UGA: '乌干达',
  UMI: '美国边远小岛',
  USA: '美国',
  URY: '乌拉圭',
  UZB: '乌兹别克斯坦',
  VAT: '圣座（梵蒂冈）',
  VCT: '圣文森特和格林纳丁斯',
  VEN: '委内瑞拉',
  VGB: '英属维京群岛',
  VIR: '美属维京群岛',
  VNM: '越南',
  VUT: '瓦努阿图',
  WLF: '瓦利斯和富图纳',
  WSM: '萨摩亚',
  XKX: '科索沃',
  YEM: '也门',
  MYT: '马约特',
  ZAF: '南非',
  ZMB: '赞比亚',
  ZWE: '津巴布韦',
});

export { COUNTRY_CODE_CN };
