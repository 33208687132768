import '@styles/react-vis.less';
import '@styles/styles.less';
import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n';
import { ProvideAuth } from '@/hooks/use-auth';
import 'reflect-metadata';
import ReferralDetector from '@/components/utils/ReferralDetector';
import { PreventLeaveProvider } from '@/hooks/use-prevent';
import * as Sentry from '@sentry/node';

const _JSXStyle = require('styled-jsx/style').default;

if (typeof global !== 'undefined') {
  Object.assign(global, { _JSXStyle });
}
//////////////////////////////////////////////////////////////////
const SENTRY_DSN = process.env.SENTRY_DSN;
const RELEASE_NAME = process.env.RELEASE_NAME;
Sentry.init({
  enabled: !!SENTRY_DSN,
  dsn: SENTRY_DSN,
  release: RELEASE_NAME,
});

if (!!!SENTRY_DSN) {
  //console.log('Sentry not enabled');
}
//////////////////////////////////////////////////////////////////

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const HTMLHead: React.FunctionComponent = () => {
  return (
    <Head>
      <meta name='viewport' content='user-scalable=no,initial-scale=1,maximum-scale=1,minimum-scale=1,width=device-width,height=device-height' />
      <meta charSet='utf-8' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0'></meta>
      <title>Qupital Platform</title>
      <link rel='icon' type='image/x-icon' href={require('@/static/logo/favicon.ico')} />
    </Head>
  );
};

class MyApp extends App {
  render(): JSX.Element {
    const { Component, pageProps } = this.props;

    const { err } = this.props as any;
    const modifiedPageProps = { ...pageProps, err };

    return (
      <>
        <HTMLHead />
        <I18nextProvider i18n={i18n}>
          <PreventLeaveProvider>
            <ProvideAuth>
              <Component {...modifiedPageProps} />
              <ReferralDetector />
            </ProvideAuth>
          </PreventLeaveProvider>
        </I18nextProvider>
      </>
    );
  }
}

export default MyApp;
