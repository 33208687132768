const APPLICATION_LIST_HK = Object.freeze({
  'application-list.title': '申請列表',
  'application-list.empty.list': '暫無數據',
  'application-list.new.application': '添加申請',
  'application-list.application.no': '申請編號',
  'application-list.application.status': '申請狀態',
  'application-list.application.create-date': '創建日期',
  'application-list.application.update-date': '最後修改日期',
  'application-list.application.remarks': '備註',
  'application-list.application.applicationType': '申請類型',
  'application-list.application.reason': '拒絕原因',
  'application-list.application.company.name': '公司名稱',
  'application-list.application.store.isValidated': '店鋪已驗證',
  'application-list.application.applicant.status': '申請人狀態',
  'application-list.application.mainlandcompanyname': '內地公司名稱',
  'application-list.application.hongkongcompanyname': '香港公司名稱',
  'application-list.admin.mode': '管理員模式',
  'application-list.admin.partner.referral': '生成夥伴連結',
  'application-list.admin.sales.referral': '複製推薦碼',
});



const APPLICATION_HK = Object.freeze({
  'application.status.draft': '草稿',
  'application.status.under-review': '審批中',
  'application.status.approved': '已批核，待簽約',
  'application.status.rejected': '已否決',
  'application.status.to-be-signed': '請簽約',
  'application.status.to-be-verified': '已簽約，核實中',
  'application.status.accepted': '可申請貸款',
  'application.applicant.status.applicant': 'Applicant',
  'application.applicant.status.prospective.seller': 'Propsective Seller',
  'application.applicant.status.rejected.applicant': 'Rejected Applicant',
  'application.applicant.status.seller': 'Seller',
});

export { APPLICATION_LIST_HK, APPLICATION_HK };



