import React from 'react';
import { useAsync } from 'react-use';
import { useCookies } from 'react-cookie';
import _ from 'lodash';

import { useAuth } from '@/hooks/use-auth';
import APIRequest from '@/lib/APIRequest';

const ReferralDetector: React.FunctionComponent = (): JSX.Element => {
  const { user, getCredentials, isSignedIn } = useAuth();
  const [cookies, setCookie, removeCookie] = useCookies(['customerUUID']);
  const api = new APIRequest();

  useAsync(async () => {
    if (isSignedIn && !_.isNil(cookies.customerUUID)) {
      try {
        const response = await api
          .setAuthToken((await getCredentials())?.getAccessToken().getJwtToken())
          .setAPI('partner/customer/register')
          .setMethod('POST')
          .setData({
            customerUUID: cookies.customerUUID,
          })
          .send();
        if (response.ok) {
          removeCookie('customerUUID');
        }
      } catch (err) {
        //console.log(err);
      }
    }
  }, [user?.id, isSignedIn]);
  return <></>;
};

export default ReferralDetector;
