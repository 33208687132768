const REFERRAL_HK = Object.freeze({
  'referral.url.generation.title': '生成夥伴推薦連結',
  'referral.url.generation.partner': '夥伴',
  'referral.url.generation.partner.customer.id': '夥伴客戶ID',
  'referral.url.generation.partner.select': '請選擇夥伴',
  'referral.url.generation.partner.customer.id.input': '請輸入夥伴客戶ID',
  'referral.url.generation.partner.application-type.input': '請選擇申請種類',
  'referral.url.generation.url': '推薦連結：',
  'referral.url.generation.sellerApplicant': 'Qiao Yi Dai',
  'referral.url.generation.expressApplicant': 'Express Application',
});

export { REFERRAL_HK };
