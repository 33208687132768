import { COUNTRY_CODE_CN } from './zh-CN/country-code';
import { AUTH_CN } from './zh-CN/authentication';
import { ERROR_CN } from './zh-CN/error';
import { LANDING_CN } from './zh-CN/landing';
import { COMMON_CN } from './zh-CN/common';
import { MENU_CN } from './zh-CN/menu';
import { APPLICATION_CN, APPLICATION_LIST_CN } from './zh-CN/application';
import { FORM_CN } from './zh-CN/form';
import { ADMIN_CN } from './zh-CN/admin';
import { REFERRAL_CN } from './zh-CN/referral';

export default {
  // General
  ...COMMON_CN,
  ...ERROR_CN,
  ...COUNTRY_CODE_CN,

  // Menu
  ...MENU_CN,

  // Page Specific
  ...LANDING_CN,
  ...AUTH_CN,
  ...APPLICATION_LIST_CN,
  ...APPLICATION_CN,
  ...ADMIN_CN,

  // Form
  ...FORM_CN,

  // Referral
  ...REFERRAL_CN,
};
