const REFERRAL_CN = Object.freeze({
  'referral.url.generation.title': '生成伙伴推荐连结',
  'referral.url.generation.partner': '伙伴',
  'referral.url.generation.partner.customer.id': '伙伴客户ID',
  'referral.url.generation.partner.select': '请选择伙伴',
  'referral.url.generation.partner.customer.id.input': '请输入伙伴客户ID',
  'referral.url.generation.partner.application-type.input': '请选择申请种类',
  'referral.url.generation.url': '推荐连结：',
  'referral.url.generation.sellerApplicant': 'Qiao Yi Dai',
  'referral.url.generation.expressApplicant': 'Express Application',
});

export { REFERRAL_CN };
