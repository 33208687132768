const ERROR_HK = Object.freeze({
  'error.validation.required': '必填專案',
  'error.validation.email.format': '電郵格式錯誤',
  'error.validation.password.pattern': '密碼長度至少為8個字元, 並至少包含一個數位及一個字母',
  'error.validation.confirm.password.mismatch': '兩次輸入密碼不一致，請重新輸入',
  'error.validation.no.whitespace': '字串不能存在空格',

  //Login
  'error.login.fail.title': '登入失敗',
  'error.login.fail.description': '請檢查帳戶密碼是否正確',

  //Forgot password
  'error.forgot-password.fail.title': '忘記密碼發生錯誤',
  'error.forgot-password.fail.description': '請稍候再試過',

  //Reset forgot password
  'error.reset-forgot-password.fail.title': '重設密碼發生錯誤',
  'error.reset-forgot-password.fail.description': '請稍候再試過',

  'error.common': '發生錯誤',
  'error.referral.INVALID_JWT_TOKEN': '認證碼不正確, 請聯絡客服',
  'error.referral.TOKEN_ALREADY_USED': '認證碼已被使用, 請聯絡客服',
});

export { ERROR_HK };

