const AUTH_CN = Object.freeze({
  'login.title': '登录<br/> 你的桥彼道<br/> 帐户',
  'login.subtitle': '欢迎回来！请登入你的帐户',
  'login.login': '登录',
  'login.password': '密码',
  'login.forgot-password': '忘记密码',
  'login.register': '注册帐户',
  'forgot-password.title': '忘记密码',
  'forgot-password.subtitle': '重设密码的电邮会直接发送到你的电邮地址',
  'forgot-password.completed': '电邮经已发送到你的电邮地址, 请查收',
  'forgot-password.go.back.to.login': '返回登录页面',
  'registration.title': '注册帐户',
  'registration.subtitle': '请填写资料以注册小桥贷帐户',
  'registration.password': '密码',
  'registration.confirm.password': '确认密码',
  'registration.lastname': '姓氏',
  'registration.firstname': '名字',
  'registration.job.position': '职位',
  'registration.phone.number': '电话号码',
  'registration.register': '注册',
  'registration.have.account.already': '使用已有帐户登录',
  'reset-forgot-password.title': '重设密码',
  'reset-forgot-password.subtitle': '请输入新密码',
  'reset-forgot-password.password': '新密码',
  'reset-forgot-password.confirm.password': '确认新密码',
  'reset-forgot-password.reset': '重设密码',
  'reset-forgot-password.completed': '你的密码经已被重设, 请重新登录',
  'reset-forgot.password.go.back.to.login': '返回登录页面',
});

export { AUTH_CN };
