const MENU_CN = Object.freeze({
  'main-menu.contact-us': '联系我们',
  'main-menu.faq': '常见问题',
  'main-menu.home': '回到主页',
  'main-menu.logout': '退出',
  'main-menu.contact-us.phone': '电话',
  'main-menu.contact-us.email': '桥易贷查询电邮',
  'main-menu.contact-us.quantumEmail': 'Quantum查询电邮',
  'main-menu.contact-us.wechat': '微信ID',
});

export { MENU_CN };
