const COMMON_CN = Object.freeze({
  'common.email': '电邮',
  'common.loading': '请稍等...',
  'common.go.back': '上一頁',
  'common.submit': '提交',
  'common.confirm': '确定',
  'common.reset': '重设',
  'common.search': '搜索',
  'common.cancel': '取消',
  'common.pleaseselect': '请选择',
  'common.search.keyword': '关键词',
  'common.welcome.title': '欢迎回到桥彼道',
  'common.welcome.subtitle': '您的全新借贷体验',
  'common.welcomeback': '欢迎回来 {{user.username}}',
  'common.back': '返回',
  'common.edit': '编辑',
  'common.btn.convert': 'Convert',
  'common.btn.auto-fill': 'Auto Fill',
  'common.error.pagenotfound': '抱歉，你访问的页面不存在',
  'common.error.pleasesignin': '抱歉，请先登录',
  'common.error.accessdenied': '抱歉，拒绝访问',
  'common.error.unknown': '抱歉，系统发生错误',
  'common.application.status': '申请状态',
  'common.application.no': '申请编号',
  'common.operation.draftsaved': '草稿已保存',
  'common.operation.submitted': '申请已提交',
  'common.operation.return': 'The application\'s status has been set to return.',
  'common.operation.review': '{{action}} of {{review}} is submitted',
  'common.operation.migrate': 'Seller is successfully migrated to Qupital Systems',
  'common.operation.created': '申请成功添加',
  'common.operation.error': '发生错误，请联系客服',
  'common.operation.modify.application.type': 'The type of application {{applicationNumber}} has been changed to {{applicationType}}',
  'common.operation.auto-fill': 'The application {{applicationNumber}} has been filled',
  'common.operation.copy': '已复制',
});

export { COMMON_CN };
