import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, zhCN, zhHK } from './locales';
import moment from 'moment';
import LanguageDetector from 'i18next-browser-languagedetector';


// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en,
  },
  'zh-CN': {
    translation: zhCN,
  },
  'zh-HK': {
    translation: zhHK
  },
  'zh-TW': {
    translation: zhHK
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'zh-CN',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss,
      format: function (value, format, lng): string {
        if (value instanceof Date)
          return moment(value)
            .locale(lng)
            .format(format);
        return value ? value.toString() : '';
      },
    },
    react: {
      wait: true,
    },
  });

export default i18n;
