const APPLICATION_LIST_CN = Object.freeze({
  'application-list.title': '申请列表',
  'application-list.empty.list': '暂无数据',
  'application-list.new.application': '添加申请',
  'application-list.application.no': '申请编号',
  'application-list.application.status': '申请状态',
  'application-list.application.create-date': '创建日期',
  'application-list.application.update-date': '最后修改日期',
  'application-list.application.remarks': '备注',
  'application-list.application.applicationType': '申请類型',
  'application-list.application.reason': '拒绝原因',
  'application-list.application.company.name': '公司名称',
  'application-list.application.store.isValidated': '店铺已验证',
  'application-list.application.applicant.status': '申请人状态',
  'application-list.application.mainlandcompanyname': '内地公司名称',
  'application-list.application.hongkongcompanyname': '香港公司名称',
  'application-list.admin.mode': '管理员模式',
  'application-list.admin.partner.referral': '生成伙伴连结',
  'application-list.admin.sales.referral': '复制推荐码',
});

const APPLICATION_CN = Object.freeze({
  'application.status.draft': '草稿',
  'application.status.under-review': '审批中',
  'application.status.approved': '已批核，待签约',
  'application.status.rejected': '已否决',
  'application.status.to-be-signed': '请签约',
  'application.status.to-be-verified': '已签约，核实中',
  'application.status.accepted': '可申请贷款',
  'application.applicant.status.applicant': 'Applicant',
  'application.applicant.status.prospective.seller': 'Propsective Seller',
  'application.applicant.status.rejected.applicant': 'Rejected Applicant',
  'application.applicant.status.seller': 'Seller',
});

export { APPLICATION_LIST_CN, APPLICATION_CN };
