const COUNTRY_CODE_HK = Object.freeze({
  AND: '安道爾',
  ARE: '阿拉伯聯合酋長國',
  AFG: '阿富汗',
  ATG: '安提瓜和巴布達',
  AIA: '安圭拉',
  ALB: '阿爾巴尼亞',
  ARM: '亞美尼亞',
  AGO: '安哥拉',
  ATA: '南極洲',
  ARG: '阿根廷',
  ASM: '美屬薩摩亞',
  AUT: '奧地利',
  AUS: '澳大利亞',
  ABW: '阿路巴',
  ALA: '奧蘭群島',
  AZE: '亞塞拜然',
  BIH: '波士尼亞和黑山共和國',
  BRB: '巴巴多斯',
  BGD: '孟加拉',
  BEL: '比利時',
  BFA: '布吉納法索',
  BGR: '保加利亞',
  BHR: '巴林',
  BDI: '蒲隆地',
  BEN: '貝南',
  BLM: '聖巴泰勒米',
  BMU: '百慕達',
  BRN: '汶萊',
  BOL: '玻利維亞',
  BES: '博奈爾島、聖尤斯達蒂斯和薩巴',
  BRA: '巴西',
  BHS: '巴哈馬',
  BTN: '不丹',
  BVT: '布維特島',
  BWA: '博茨瓦納',
  BLR: '白俄羅斯',
  BLZ: '伯利茲',
  CAN: '加拿大',
  CCK: '科科斯群島',
  COD: '扎伊爾',
  CAF: '中非共和國',
  COG: '剛果',
  CHE: '瑞士',
  CIV: '象牙海岸',
  COK: '庫克群島',
  CHL: '智利',
  CMR: '喀麥隆',
  CHN: '中國',
  COL: '哥倫比亞',
  CRI: '哥斯大黎加',
  CUB: '古巴',
  CPV: '佛得角',
  CUW: '古拉梳',
  CXR: '聖誕島',
  CYP: '賽普勒斯',
  CZE: '捷克共和國',
  DEU: '德國',
  DJI: '吉布地',
  DNK: '丹麥',
  DMA: '多明尼加',
  DOM: '多明尼加共和國',
  DZA: '阿爾及利亞',
  ECU: '厄瓜多爾',
  EST: '愛沙尼亞',
  EGY: '埃及',
  ESH: '西撒哈拉',
  ERI: '厄利垂亞',
  ESP: '西班牙',
  ETH: '衣索比亞',
  FIN: '芬蘭',
  FJI: '斐濟',
  FLK: '福克蘭群島',
  FSM: '密克羅尼西亞',
  FRO: '法羅群島',
  FRA: '法國',
  GAB: '加蓬',
  GBR: '英國',
  GRD: '格林納達',
  GEO: '喬治亞',
  GUF: '法屬圭亞那',
  GGY: '格恩西島',
  GHA: '迦納',
  GIB: '直布羅陀',
  GRL: '格陵蘭',
  GMB: '甘比亞',
  GIN: '幾內亞',
  GLP: '瓜德羅普島',
  GNQ: '赤道幾內亞',
  GRC: '希臘',
  SGS: '南喬治亞島和南桑威齊群島',
  GTM: '瓜地馬拉',
  GUM: '關島',
  GNB: '幾內亞比索',
  GUY: '圭亞那',
  HKG: '中國香港',
  HMD: '赫德與麥克唐納群島',
  HND: '宏都拉斯',
  HRV: '克羅埃西亞',
  HTI: '海地',
  HUN: '匈牙利',
  IDN: '印尼',
  IRL: '愛爾蘭',
  ISR: '以色列',
  IMN: '曼島',
  IND: '印度',
  IOT: '英屬印度洋領地',
  IRQ: '伊拉克',
  IRN: '伊朗伊斯蘭共和國',
  ISL: '冰島',
  ITA: '義大利',
  JEY: '澤西島',
  JAM: '牙買加',
  JOR: '約旦',
  JPN: '日本',
  KEN: '肯亞',
  KGZ: '吉爾吉克斯坦',
  KHM: '柬埔寨',
  KIR: '基里巴斯',
  COM: '科摩羅',
  KNA: '聖基茨和尼維斯',
  PRK: '朝鮮民主共和國',
  KOR: '大韓民國',
  KWT: '科威特',
  CYM: '開曼群島',
  KAZ: '哈薩克',
  LAO: '老撾人民民主共和國',
  LBN: '黎巴嫩',
  LCA: '聖卢西亞',
  LIE: '列支敦斯登',
  LKA: '斯里蘭卡',
  LBR: '利比瑞亞',
  LSO: '萊索托',
  LTU: '立陶宛',
  LUX: '盧森堡',
  LVA: '拉脫維亞',
  LBY: '阿拉伯利比亞民眾國',
  MAR: '摩洛哥',
  MCO: '摩納哥',
  MDA: '摩爾多瓦共和國',
  MNE: '黑山共和國',
  MAF: '聖馬丁',
  MDG: '馬達加斯加',
  MHL: '馬紹爾群島',
  MKD: '前南斯拉夫馬其頓共和國',
  MLI: '馬里',
  MMR: '緬甸',
  MNG: '蒙古',
  MAC: '中國澳門',
  MNP: '北馬里亞納群島',
  MTQ: '馬提尼克群島',
  MRT: '茅利塔尼亞',
  MSR: '蒙塞拉特群島',
  MLT: '馬爾他',
  MUS: '模里西斯',
  MDV: '瑪律代夫',
  MWI: '馬拉威',
  MEX: '墨西哥',
  MYS: '馬來西亞',
  MOZ: '莫三比克',
  NAM: '纳米比亚',
  NCL: '新喀里多尼亚',
  NER: '尼日尔',
  NFK: '诺福克岛',
  NGA: '尼日利亚',
  NIC: '尼加拉瓜',
  NLD: '荷兰',
  NOR: '挪威',
  NPL: '尼泊尔',
  NRU: '瑙鲁',
  NIU: '纽埃',
  NZL: '新西兰',
  OMN: '阿曼',
  PAN: '巴拿马',
  PER: '秘鲁',
  PYF: '法属波利尼西亚',
  PNG: '巴布亚新几内亚',
  PHL: '菲律宾',
  PAK: '巴基斯坦',
  POL: '波兰',
  SPM: '圣皮埃尔和密克隆',
  PCN: '皮特凯恩群岛',
  PRI: '波多黎各',
  PSE: '巴勒斯坦领土',
  PRT: '葡萄牙',
  PLW: '帕劳',
  PRY: '巴拉圭',
  QAT: '卡塔尔',
  REU: '留尼汪',
  ROU: '罗马尼亚',
  SRB: '塞尔维亚',
  RUS: '俄罗斯',
  RWA: '卢旺达',
  SAU: '沙特阿拉伯',
  SLB: '所罗门群岛',
  SYC: '塞舌尔群岛',
  SDN: '苏丹',
  SWE: '瑞典',
  SGP: '新加坡',
  SHN: '圣赫勒拿',
  SVN: '斯洛文尼亚',
  SJM: '斯瓦尔巴特和扬马延',
  SVK: '斯洛伐克共和国',
  SLE: '塞拉利昂',
  SMR: '圣马力诺',
  SEN: '塞内加尔',
  SOM: '索马里',
  SUR: '苏里南',
  SSD: '南苏丹',
  STP: '圣多美和普林西比',
  SLV: '萨尔瓦多',
  SXM: '圣马丁岛',
  SYR: '叙利亚',
  SWZ: '斯威士兰',
  TCA: '特克斯和凯科斯群岛',
  TCD: '乍得',
  ATF: '法属南部领土',
  TGO: '多哥',
  THA: '泰国',
  TJK: '塔吉克斯坦',
  TKL: '托克劳',
  TLS: '东帝汶',
  TKM: '土库曼斯坦',
  TUN: '突尼斯',
  TON: '汤加',
  TUR: '土耳其',
  TTO: '特立尼达和多巴哥',
  TUV: '图瓦卢',
  TWN: '中国台湾',
  TZA: '坦桑尼亚',
  UKR: '乌克兰',
  UGA: '乌干达',
  UMI: '美国边远小岛',
  USA: '美国',
  URY: '乌拉圭',
  UZB: '乌兹别克斯坦',
  VAT: '圣座（梵蒂冈）',
  VCT: '圣文森特和格林纳丁斯',
  VEN: '委内瑞拉',
  VGB: '英属维京群岛',
  VIR: '美属维京群岛',
  VNM: '越南',
  VUT: '瓦努阿图',
  WLF: '瓦利斯和富图纳',
  WSM: '萨摩亚',
  XKX: '科索沃',
  YEM: '也门',
  MYT: '马约特',
  ZAF: '南非',
  ZMB: '赞比亚',
  ZWE: '津巴布韦',
});

export { COUNTRY_CODE_HK };

