import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { useMount, useToggle, useUnmount, useDebounce } from 'react-use';

export interface PreventLeaveInfo {
  isPrevent: boolean;
  togglePrevent: (next?: boolean) => void;
}

export const PreventLeaveContext = React.createContext<PreventLeaveInfo>(undefined);
PreventLeaveContext.displayName = 'PreventLeaveContext';

export const PreventLeaveProvider: React.FunctionComponent<PropsWithChildren<{}>> = ({ children }: PropsWithChildren<{}>) => {
  const [prevent, togglePrevent] = useToggle(false);

  const handleBeforeUnload = event => {
    //console.log('IS IT', prevent);
    if (prevent) event.returnValue = `Are you sure you want to leave?`;
  };

  if (typeof window !== 'undefined' && window.document && window.document.createElement) {
    useEffect(() => {
      window.addEventListener('beforeunload', handleBeforeUnload);
      return (): void => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    });
  }

  return (
    <PreventLeaveContext.Provider
      value={{
        isPrevent: prevent,
        togglePrevent,
      }}>
      {children}
    </PreventLeaveContext.Provider>
  );
};

export const usePreventLeaveAuto = (value?: boolean): PreventLeaveInfo => {
  const { isPrevent, togglePrevent } = useContext(PreventLeaveContext);
  useMount(() => togglePrevent(value == true));
  useUnmount(() => togglePrevent(false));
  useDebounce(() => togglePrevent(value == true), 500, [value]);
  return { isPrevent, togglePrevent };
};
