const COMMON_HK = Object.freeze({
  'common.email': '電郵',
  'common.loading': '請稍等...',
  'common.go.back': '上一頁',
  'common.submit': '提交',
  'common.confirm': '確定',
  'common.reset': '重設',
  'common.search': '搜索',
  'common.cancel': '取消',
  'common.pleaseselect': '請選擇',
  'common.search.keyword': '關鍵詞',
  'common.welcome.title': '歡迎回到橋彼道',
  'common.welcome.subtitle': '您的全新借貸體驗',
  'common.welcomeback': '歡迎回來 {{user.username}}',
  'common.back': '返回',
  'common.edit': '編輯',
  'common.btn.convert': 'Convert',
  'common.error.pagenotfound': '抱歉，你訪問的頁面不存在',
  'common.error.pleasesignin': '抱歉，請先登錄',
  'common.error.accessdenied': '抱歉，拒絕訪問',
  'common.error.unknown': '抱歉，系統發生錯誤',
  'common.application.status': '申請狀態',
  'common.application.no': '申請編號',
  'common.operation.draftsaved': '草稿已保存',
  'common.operation.submitted': '申請已提交',
  'common.operation.return': 'The application\'s status has been set to return.',
  'common.operation.review': '{{action}} of {{review}} is submitted',
  'common.operation.migrate': 'Seller is successfully migrated to Qupital Systems',
  'common.operation.created': '申請成功添加',
  'common.operation.error': '發生錯誤，請聯繫客服',
  'common.operation.modify.application.type': 'The type of application {{applicationNumber}} has been changed to {{applicationType}}',
  'common.operation.copy': '已複製',
});

export { COMMON_HK };

