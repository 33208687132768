const LANDING_HK = Object.freeze({
  'landing.title': '登入\n你的橋彼道\n帳戶',
  'landing.subtitle': '歡迎回來！請登入你的帳戶',

  'landing.referral.title.lian_lian_pay': '連連客戶',
  'landing.referral.subtitle.lian_lian_pay': '歡迎你使用橋易貸服務',

  'landing.referral.title.winit_corporation': '萬邑通客戶',
  'landing.referral.subtitle.winit_corporation': '歡迎你使用橋易貸服務',
});

export { LANDING_HK };
