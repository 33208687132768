const MENU_HK = Object.freeze({
  'main-menu.contact-us': '聯繫我們',
  'main-menu.faq': '常見問題',
  'main-menu.home': '回到主頁',
  'main-menu.logout': '退出',
  'main-menu.contact-us.phone': '電話',
  'main-menu.contact-us.email': '橋易貸查詢電郵',
  'main-menu.contact-us.quantumEmail': 'Quantum查詢電郵',
  'main-menu.contact-us.wechat': '微信ID',
});

export { MENU_HK };
