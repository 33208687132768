export const FORM_HK = Object.freeze({
  'form.common.stepn': '步驟{{n}}： {{name}}',
  'form.common.saved': '儲存',
  'form.common.savedraft': '儲存草稿',
  'form.common.step.previous': '上一步',
  'form.common.step.next': '下一步',
  'form.common.step.submit': '提交',
  'form.common.upload.subscript': '點擊或將文件拖拽到這裡上傳',
  'form.common.upload.onlyfileformat': '上傳檔案只接受 {{formats}} 格式',
  'form.common.upload.unacceptableformat': undefined,
  'form.common.confirmdelete': '你確定要刪除嗎？ ',
  'form.components.datepicker.placeholder': '選擇日期',
  'form.common.confirm.convert': 'Confirm converting current application to {{applicationType}}.',
  'form.common.confirm.auto-fill': 'Confirm auto filling current application.',
  //
  'form.basicinfo.nav': '公司基本資料',
  'form.basicinfo.china.companyname': '內地公司名稱',
  'form.basicinfo.china.socialcredit': '內地公司統一社會信用代碼',
  'form.basicinfo.china.officeaddress': '內地公司辦公位址',
  'form.basicinfo.china.employeenumber': '內地公司員工人數',
  'form.basicinfo.china.websiteaddress': '內地公司網頁位址',
  'form.basicinfo.china.isofficerental': '內地公司辦公室是否為租借?',
  'form.basicinfo.china.officeleasedate': '內地公司辦公室租約到期日',
  'form.basicinfo.china.incorporation': '內地公司成立日期',
  'form.basicinfo.hongkong.companyname': '香港公司名稱',
  'form.basicinfo.hongkong.businessRegistrationNo': '香港公司商業登記號碼',
  'form.basicinfo.hongkong.companyRegistrationNo': '香港公司註冊號碼 ',
  'form.basicinfo.hongkong.officeaddress': '香港辦公室/註冊位址',
  'form.basicinfo.hongkong.employeenumber': '香港公司員工人數',
  'form.basicinfo.hongkong.websiteaddress': '香港公司網頁位址',
  'form.basicinfo.hongkong.isofficerental': '香港公司辦公室是否為租借?',
  'form.basicinfo.hongkong.officeleasedate': '香港公司辦公室租約到期日',
  'form.basicinfo.hongkong.incorporation': '香港公司成立日期',
  //
  'form.uploaddoc.nav': '上載文檔',
  'form.uploaddoc.registration.title': '營業證明',
  'form.uploaddoc.registration.hk.businessregistration': '商業登記證 (香港公司)',
  'form.uploaddoc.registration.china.mna': '公司章程 (中國公司)',
  'form.uploaddoc.registration.china.lplicense': '企業法人營業執照 (中國公司)',
  'form.uploaddoc.registration.china.officelease': '辦公室租約 (中國公司)',
  'form.uploaddoc.registration.hk.financialStatement': '最近兩年的經審核財務報表',
  'form.uploaddoc.registration.hk.officelease': '公司商鋪的有效租約',
  'form.uploaddoc.persons.title': '法人, 自然人股東和董事身份證明',
  'form.uploaddoc.persons.hk.idpassport': '股東和董事身份證或護照 (香港公司)',
  'form.uploaddoc.persons.china.idpassport': '法人, 自然人股東和董事身份證正反面 (中國公司)',
  'form.uploaddoc.persons.hkchina.address': '法人, 自然人股東和董事地址證明 (香港及中國公司 ， 3個月內有效)',
  'form.uploaddoc.persons.hk.address': '股東和董事於過去三個月的住址證明',
  'form.uploaddoc.persons.remarks.1': '如公司擁有多於一名董事，等於或過半數的董事都需要提供檔',
  'form.uploaddoc.persons.remarks.2': '如公司擁有多於一名股東，請提供持股佔比等於或多於70%的股東的檔 ( 包括最大股東)',
  'form.uploaddoc.statement.title': '企業流水證明',
  'form.uploaddoc.statement.bankstatement': '過去6個月銀行流水帳單 (香港及中國公司)',
  'form.uploaddoc.statement.thirdparty': '過去6個月第三方支付平臺流水記錄',
  'form.uploaddoc.agreement.title': '簽署同意書',
  'form.uploaddoc.agreement.download': '下載同意書',
  'form.uploaddoc.agreement.crif': '信貸機構同意書 - 道口金科',
  'form.uploaddoc.agreement.tu': '信貸機構同意書 - 環聯資訊有限公司',
  'form.uploaddoc.creditreport.title': '信貸報告',
  'form.uploaddoc.creditreport.company': '人民銀行企業信用報告 (中國公司)',
  'form.uploaddoc.creditreport.persons': '人民銀行個人徵信報告 (中國公司股東及董事)',
  'form.uploaddoc.financialreport.title': '財務檔',
  'form.uploaddoc.financialreport.audit': '最近三年的審計報告 (可選)',
  'form.uploaddoc.financialreport.internalledger': '最新的公司內部管理帳 (可選)',
  'form.uploaddoc.financialreport.otherlending': '與其他貸款機構的借貸檔 (如有)',
  'form.uploaddoc.agreement.crif.description':
    '備註:\n北京道口金科科技有限公司我司在中國的信貸合作夥伴。 \n簡介:\n北京道口金科科技有限公司成立於2018年，孵化於清華大學五道口金融學院互聯網金融實驗室，是一家專業金融科技公司。 道口金科專注中小微企業智慧信貸，通過人工智慧與大數據技術，提供全流程信貸解決方案。 \n',
  'form.uploaddoc.agreement.crif.helper': '請下載書並並蓋章（國內公司公章）及簽署，然後進行掃描並同意上載已簽署及蓋章的同意書。 ',
  'form.ea.uploaddoc.persons.remarks.2': '如公司擁有多於一名股東，請提供持股佔比等於或多於50%的股東的檔 ( 包括最大股東)',

  //form specially for POS application
  'form.uploaddoc.pos.nav': '上載文檔',
  'form.uploaddoc.registration.pos.businessregistration': '商業登記證',
  'form.uploaddoc.statement.pos.bankstatement': '公司銀行戶口過去六個月的銀行月結單',
  'form.uploaddoc.statement.pos.financialstatement': '最近兩年的已審核財務報表',
  'form.uploaddoc.registration.pos.financialstatement': '最近兩年的已審核財務報表',
  'form.uploaddoc.persons.pos.idpassport': '股東或/及董事的身份證/護照副本',
  'form.uploaddoc.persons.pos.id': '股東或/及董事身份證明 ¹ ²',
  'form.uploaddoc.persons.pos.addressproof': '股東或/及董事於過去三個月的住址證明 ¹ ²',
  'form.uploaddoc.persons.pos.remarks.1': '如公司擁有多於一名董事，一半或以上的董事都需要提供文件',
  'form.uploaddoc.persons.pos.remarks.2': '如公司擁有多於一名股東，請提供持股量50%或以上的股東的文件 (包括最大股東)',
  'form.uploaddoc.agreement.pos.helper': `閣下的環聯信貸記錄會作為橋彼道批核貸款的參考資料之一(如適用)。
  請提供最大股東所簽署的同意書。先下載並列印，簽署後上載同意書掃描檔至本表格。`,
  'form.uploaddoc.agreement.pos.tu.description': '1. 橋彼道有機會以電郵要求其他股東/董事簽署同意書以便查詢相關的信貸記錄。',
  'form.uploaddoc.statement.pos.rentproof': '最新的店鋪租金繳納證明',
  'form.uploaddoc.statement.pos.generalrestaurantlicence': '普通食肆牌照 (如貴公司經營食肆，請按情況提供)',
  'form.uploaddoc.statement.pos.lightrefreshmentlicence': '小食食肆牌照 (如貴公司經營食肆，請按情況提供)',
  'form.uploaddoc.statement.pos.liquorlicence': '酒牌 (如貴公司有販賣酒精，請提供)',
  'form.basicinfo.pos.bankname': '銀行名稱',
  'form.basicinfo.pos.bankname.gobackselect': '重設',
  'form.basicinfo.pos.bankname.pleaseselect': '請選擇銀行',
  'form.basicinfo.pos.bankaccount': '銀行戶口號碼',
  'form.basicinfo.pos.beneficiary': '銀行戶口持有人名稱',

  'form.basicinfo.pos.companyname': '公司註冊名稱',
  'form.basicinfo.pos.businessRegistrationNo': '公司商業登記號碼',
  'form.basicinfo.pos.officeaddress': '辦公室/註冊地址',
  'form.basicinfo.pos.posincomeratio': 'POS卡機收入佔店鋪總收入比例（%）',
  'form.basicinfo.pos.loanamount': '需求貸款金額(HKD)',
  'form.basicinfo.pos.loanusage': '貸款用途',
  'form.confirm.pos.contract': `{{companyNameHongkong}}（”本公司“) 向Qupital Limited （”橋彼道“) 確認並保證本貸款申請(“本申請”)內所載資料均是真實無訛、準確並完整。本公司同意橋彼道有權隨時向任何第叁者索取有關本公司的資料，包括但不限於向信貸資料服務機構(包括環聯資訊有限公司)以及支付機構進行查閱。本公司同意，橋彼道有機會以電郵或其他方式要求本公司任何股東或董事簽署相關同意書以便查詢相關的信貸記錄。

  本公司確認提交本申請之人士已獲得本公司授權進行一切與本申請有關之事項。
  
  本公司謹此聲明本公司具償債能力，現時沒有涉及任何破產、訴訟或行政程序，或此等待決事項，或按本公司在作出一切合理查詢後所知，威脅會展開該等事項。本公司聲明本公司並無與其他貸款人作出任何對本公司償還橋彼道就此申請批出的貸款 (如有) 構成阻礙的安排。此外，本公司確認及同意在本公司完全償還橋彼道貸款之前，不會自願性申請清盤。
  
  本公司明白申請貸款批核乃參考本公司提供之申請資料及文件，貸款審批結果最終由橋彼道決定，而橋彼道沒有責任就拒絕此貸款申請提供任何原因。橋彼道對本申請及由其直接或間接引起的一切的爭議擁有最終決定權。本公司明白並同意在收到橋彼道通知成功獲批結果後，本公司須確認接受橋彼道的條款及細則，當中包括《橋彼道平台條款及細則 (Key Terms and Conditions for Credit Facilities)》。

  本公司確認已經詳閱並明白附載於本申請頁面的橋彼道收集個人資料聲明（“個人資料聲明”），並且同意受橋彼道私隱政策約束，包括但不限於，橋彼道按個人資料聲明及私隱政策收集、使用及披露本公司股東，董事，員工或其他相關人士的個人資料。
  
  本公司明白為向本公司提供服務，橋彼道可能把本申請中的個人資料轉移至香港境外，交予橋彼道位於中國大陸的集團公司及/或橋彼道的代理人(包括, 但不限於, 橋彼道聘用來追討橋彼道的客戶過期的貸款的第三方公司)。該地區可能沒有與《個人資料(私隱)條例》(香港法例第四八六章)大體上相似或達致與此條例的目的相同目的之法律，亦即是說，本申請中的個人資料未必可以獲得與在香港相同或類似程度的保障。

  本公司根據《電子交易條例》(香港法例第五五叁章)第十五條同意橋彼道以電子紀錄或以電子方式向本公司提供借香港法律(包括但不限於放債人條例(香港法例第一六叁章)) 須以書面形式提供之資料。`,
  'form.confirm.pos.authorization': '本人({{authorizedPersonName}}) 確認已獲得 {{companyNameHongkong}} 的授權代其向橋彼道申請本貸款。',
  'form.confirm.pos.authorizedpersonname': '獲授權人中文/英文全名',
  'form.confirm.pos.authorizedpersonsign': '獲授權人簽署',
  'form.confirm.pos.authorizedpersonid': '獲授權人香港身份證號碼',
  'form.confirm.pos.authorizedate': '日期',
  'form.confirm.pos.authorizedpersonname.placeholder': '[獲授權人中文/英文全名]',
  'form.director.pos.form.companyid': '香港公司商業登記號碼',
  'form.uploaddoc.financialstatement.pos.remarks.1':'如公司擁有多於一個商鋪，請提供所有商鋪的有效租約',
  'form.shareholder.pos.form.id': '香港身份證號碼',
  'form.director.pos.form.id': '香港身份證號碼',
  'form.uploaddoc.registration.pos.hk.officelease': '公司商鋪的有效租約 ¹',
  //
  'form.persons.nav': '法人, 股東及董事資料',
  'form.persons.mainland.title': '內地公司資料',
  'form.persons.mainland.legalperson.title': '內地公司法人資料',
  'form.persons.mainland.legalperson.name': '內地公司法定代表人名稱',
  'form.persons.mainland.legalperson.id': '身份證號碼',
  'form.persons.mainland.legalperson.nationality': '國籍',
  'form.persons.mainland.legalperson.email': '電郵',
  'form.persons.mainland.legalperson.phonenumber': '手提電話號碼',
  'form.persons.mainland.legalperson.homeaddress': '住宅位址',
  'form.shareholder.hongkong.title': '香港公司股東資料',
  'form.shareholder.mainland.title': '內地公司股東資料',
  'form.shareholder.add': '添加股東',
  'form.shareholder.new': '新股東',
  'form.shareholder.form.type': '類型',
  'form.shareholder.form.type.person': '個人',
  'form.shareholder.form.type.company': '公司',
  'form.shareholder.form.name': '名稱/姓名(中文)',
  'form.shareholder.form.englishname': '名稱/姓名(英文)',
  'form.shareholder.form.id': '身份證號碼',
  'form.shareholder.form.nationality': '國籍',
  'form.shareholder.form.phonenumber': '手提電話號碼',
  'form.shareholder.form.position': '職位',
  'form.shareholder.form.email': '電郵',
  'form.shareholder.form.shareratio': '股權比例(%)',
  'form.shareholder.form.homeaddress': '住宅地址',
  'form.shareholder.form.enghomeaddress': '住宅地址(英文)',
  'form.shareholder.form.companyid': '香港公司商業登記號碼/內地公司統一社會信用代碼',
  'form.shareholder.form.officeaddress': '辦公地址(中文)',
  'form.shareholder.form.engofficeaddress': '辦公地址(英文)',
  'form.shareholder.form.registeredaddress': '註冊位址',
  'form.shareholder.form.registeredcountry': '註冊國家',
  'error.input.shareholder.at.least': '請提供至少{{value}}個股東資料',
  'form.director.hongkong.title': '香港公司董事資料',
  'form.director.mainland.title': '內地公司董事資料',
  'form.director.add': '添加董事',
  'form.director.new': '新董事',
  'form.director.form.type': '類型',
  'form.director.form.type.person': '個人',
  'form.director.form.type.company': '公司',
  'form.director.form.name': '名稱/姓名(中文)',
  'form.director.form.englishname': '名稱/姓名(英文)',
  'form.director.form.id': '身份證號碼',
  'form.director.form.nationality': '國籍',
  'form.director.form.phonenumber': '手提電話號碼',
  'form.director.form.position': '職位',
  'form.director.form.email': '電郵',
  'form.director.form.homeaddress': '住宅地址(中文)',
  'form.director.form.englishhomeaddress': '住宅地址(英文)',
  'form.director.form.companyid': '香港公司商業登記號碼/內地公司統一社會信用代碼',
  'form.director.form.officeaddress': '辦公地址(中文)',
  'form.director.form.engofficeaddress': '辦公地址(英文)',
  'error.input.director.at.least': '請提供至少{{value}}個董事資料',
  //
  'form.contact.nav': '聯絡人資料',
  'form.contact.title': '新聯絡人',
  'form.contact.add': '添加聯絡人',
  'form.contact.new': '新聯絡人',
  'form.contact.form.name': '名稱',
  'form.contact.form.position': '職位',
  'form.contact.form.phonenumber': '手提電話號碼',
  'form.contact.form.email': '電郵',
  //
  'form.financial.add': '添加條目',
  'form.financial.nav': '財務資料',
  'form.financial.notice': '請在步驟二輸入公司成立日期以便完成此頁。 \n如你沒有損益表或資產負債表, 請用最近12個月的公司營運狀況來填寫。 ',
  'form.financial.mainland.title': '內地公司',
  'form.financial.hongkong.title': '香港公司',
  'form.financial.card.title': '{{year.from}} - {{year.to}}',
  'form.financial.annual': '{{year, MM/YYYY}}',
  'form.financial.year': '年度',
  'form.financial.form.yearstart': '財務開始年度',
  'form.financial.form.yearend': '財務完結年度',
  'form.financial.form.currency': '貨幣',
  'form.financial.form.profitnloss': '損益表',
  'form.financial.form.operatingincome': '營業收入',
  'form.financial.form.operatingcost': '營業成本',
  'form.financial.form.expenditure': '折舊及攤銷費用',
  'form.financial.form.interestincome': '利息支出',
  'form.financial.form.financialfees': '財務費用',
  'form.financial.form.operatingprofit': '營業利潤',
  'form.financial.form.pretaxprofit': '稅前利潤',
  'form.financial.form.netprofit': '凈利潤',
  'form.financial.form.balancesheet': '資產負債表',
  'form.financial.form.asset': '資產',
  'form.financial.form.liability': '負債',
  'form.financial.form.cash': '貨幣資金',
  'form.financial.form.receivable': '應收帳款',
  'form.financial.form.inventory': '存貨',
  'form.financial.form.currentasset': '流動資產合計',
  'form.financial.form.currentliability': '流動負債合計',
  'form.financial.form.loan': '負債合計',
  'form.financial.form.amountowned': '借款總額',
  'form.financial.form.totalasset': '資產總計',
  //
  'form.liability.nav': '其他授信和未結清借款',
  'form.liability.has': '本公司沒有未結清的貸款和任何授信額度', //Update to spec
  'form.liability.add': '添加',
  'form.liability.form.entity': '借款主體名稱',
  'form.liability.form.type': '貸款方名稱',
  'form.liability.form.type.description': '銀行 / 財務機構 / 第三方支付平臺 / 網上借款平台 / 電商平台 / 其它',
  'form.liability.form.currency': '貨幣',
  'form.liability.form.amount': '額度',
  'form.liability.form.own': '截至申請日的未結清借款',
  'form.liability.form.date': '申報日期',
  'form.liability.validate.no.loan': '如公司沒有未結清的貸款請選擇此專案',
  'form.liability.validate.no.empty.entry': '如公司沒有未結清的貸款, 請刪除所有空條目',
  'form.liability.explain': '如貴司有任何未結清貸款或其他額度，請在本頁面上進行申報。 如沒有任何未結清的貸款或其他授信額度，請在以下方格中打勾',
  //
  'form.ecomshop.nav': '店舖資料',
  'form.ecomshop.description':
    '我們會以貴司月均銷售額的三到四成作為審批額度的基礎。 如貴司的月均銷售為三十萬美元，我司的額度審批將會以九萬美元至十二萬美元作為基礎。 最終額度會因應貴司的運營表現進行調整。 如你沒有損益表或資產負債表, 請用最近12個月的公司營運狀況來填寫。 ',
  'form.ecomshop.add': '添加店舖',
  'form.ecomshop.steps': '步驟',
  'form.ecomshop.amazon.title': '亞馬遜店舖',
  'form.ecomshop.amazon.sellerid': '賣家ID',
  'form.ecomshop.amazon.token': 'MWS授權碼',
  'form.ecomshop.amazon.region': '區域',
  'form.ecomshop.thirdparty': '第三方支付平臺',
  'form.ecomshop.thirdparty.placeholder': '請選擇或填寫',
  'form.ecomshop.amazon.specify': '請註明',
  'form.ecomshop.common.bankid': '店鋪對應之收款平臺帳戶銀行戶口號碼',
  'form.ecomshop.amazon.shopname': '亞馬遜店鋪名稱',
  'form.ecomshop.ebay.title': 'eBay店舖',
  'form.ecomshop.ebay.shopname': 'eBay店鋪名稱',
  'form.ecomshop.ebay.thirdparty': '第三方支付平臺',
  'form.ecomshop.ebay.specify': '請註明',
  'form.ecomshop.ebay.bankid': '已連接店鋪對應之收款平臺 帳戶銀行戶口號碼',
  'form.ecomshop.authorize': '授權',
  'form.ecomshop.authorize.inprogress': '授權進行中',
  'form.ecomshop.authorize.success': '授權成功',
  'form.ecomshop.authorize.failed': '授權失敗',
  'form.ecomshop.ebay.PayPal': '已對接的PayPal郵箱',
  'form.ecomshop.deleted': '商店已刪除',
  'form.ecomshop.must.have.shop': '請連接最少一個電商店鋪',
  'form.ecomshop.start.enter': '開始輸入',
  'form.ecomshop.shopee.title': 'Shopee店舖',
  'form.ecomshop.shopee.shopname': 'Shopee店鋪名稱',
  'form.ecomshop.lazada.title': 'Lazada店舖',
  'form.ecomshop.lazada.shopname': 'Lazada店鋪名稱',
  'form.ecomshop.tmall.is': '本公司在Tmall上進行營運， 並希望對接Tmall 的店鋪以進行申請流程。',
  'form.ecomshop.tiktok.is': '本公司在Tiktok上進行營運， 並希望對接Tiktok 的店鋪以進行申請流程。',
  'form.ecomshop.mercado.is': '本公司在Mercado上進行營運， 並希望對接Mercado 的店鋪以進行申請流程。',
  'form.ecomshop.wish.title': 'Wish店舖',
  'form.ecomshop.wish.shopname': 'Wish店鋪名稱',
  'form.ecomshop.shopify.title': 'Shopify店舖',
  'form.ecomshop.shopify.shopname': 'Shopify店鋪名稱',
  'form.ecomshop.shopline.title': 'Shopline店舖',
  'form.ecomshop.shopline.shopname': 'Shopline店鋪名稱',
  'form.ecomshop.shoplazza.title': 'Shoplazza店舖',
  'form.ecomshop.shoplazza.shopname': 'Shoplazza店鋪名稱',
  //
  'form.confirm.nav': '確認申請',
  'form.confirm.estimatedamount': '預計信用額度',
  'form.confirm.discountrate': '每筆貸款利息',
  'form.confirm.discountrate.prefix': '約',
  'form.confirm.discountrate.subfix': '% /日',
  'form.confirm.earndisclaimer': '橋彼道將就每筆貸款收取{{range}}的手續費（根據貸款週期而定，已扣除適用手續費回贈）。 ',
  'form.ea.confirm.earndisclaimer': '以上利息已包含手續費。 ',
  'form.confirm.feedisclaimer': '以上資訊不構成橋彼道有限公司對任何人作出的任何陳述，保證，承諾或要約。 如有任何爭議，橋彼道有限公司保留一切最終決定權。 ',
  'form.confirm.confirm': '本公司明白及同意以上條款',
  'form.confirm.declaration':
    '本公司已詳閱並同意以上聲明真實及正確。 本公司同意受以上聲明所列的所有條款約束。 如本公司聲明不實，本公司同意承擔所有法律責任以及向橋彼道賠償一切直接或間接引起的損失。',
  'form.confirm.contract': `{{companyNameHongkong}}以及{{companyNameMainland}} （以下合稱” 本公司“) 向Qupital Limited （”橋彼道“) 確認並保證本貸款申請(“本申請”)內所載資料均是真實無訛、準確並完整。 本公司同意橋彼道有權隨時向任何第三者索取有關本公司的資料，包括但不限於向信貸資料服務機構進行查閱。

  本公司確認提交本申請之人士已獲得本公司授權進行一切與本申請有關之事項。
  
  本公司謹此聲明本公司具償債能力，現時沒有涉及任何破產、訴訟或行政程式，或此等待決事項，或按本公司在作出一切合理查詢后所知，威脅會展開該等事項。 本公司聲明本公司並無與其他貸款人作出任何對本公司償還橋彼道就此申請批出的貸款(如有) 構成阻礙的安排。 此外，本公司確認及同意在本公司完全償還橋彼道貸款之前，不會自願性申請破產。
  
  本公司明白申請貸款批核乃參考本公司提供之申請資料及文件，貸款審批結果最終由橋彼道決定，而橋彼道沒有責任就拒絕此貸款申請提供任何原因。 橋彼道對本申請及由其直接或間接引起的一切的爭議擁有最終決定權。 本公司明白並同意在收到橋彼道通知成功獲批結果後，本公司須確認接受橋彼道的條款及細則，當中包括《橋彼道平臺條款及細則》(“平台條款”)。 本公司確認已詳閱並明白平台條款，並同意受平台條款所約束。
  
  本公司確認已經詳閱並明白附載於本申請頁面的橋彼道收集個人資料聲明（“個人資料聲明”），並且同意受橋彼道私隱政策約束，包括但不限於，橋彼道按個人資料聲明及私隱政策收集、使用及披露本公司的個人資料。
  
  本公司明白為向本公司提供服務，橋彼道可能把本申請中的個人資料轉移至香港境外，交予橋彼道位於中國的集團公司及 / 或橋彼道的代理人(包括, 但不限於, 橋彼道聘用來追討橋彼道的客戶過期的貸款的第三方公司)。 該地區可能沒有與《個人資料(私隱)條例》(香港法例第四八六章)大體上相似或達致與此條例的目的相同目的之法律，亦即是說， 本申請中的個人資料未必可以獲得與在香港相同或類似程度的保障。
  
  本公司確認本公司並未與橋彼道以外的任何人(“第三方”)因促致、洽商、取得或申請此筆貸款，及 / 或因擔保或保證此筆貸款的償還，或由於與該等事務有關，達成或簽訂任何協定（本公司與本公司委任的律師純粹為提供法律服務而達成或簽訂的協定除外）。 本公司明白此確認的目的是確保本公司不會被任何第三方另行徵收費用。
  
  本公司根據《電子交易條例》(香港法例第五五三章)第十五條同意橋彼道以電子紀錄或以電子方式向本公司提供借香港法律(包括但不限於放債人條例(香港法例第一六三章)) 須以書面形式提供之資料。
  
  本公司明白橋彼道可不時向為橋彼道推薦客戶的業務合作夥伴提供費用或傭金，且本公司同意橋彼道此等安排。`,
  'form.confirm.hongkongcompany.placeholder': '[請輸入香港公司名稱]',
  'form.confirm.mainlandcompany.placeholder': '[請輸入大陸公司名稱]',
  'form.confirm.must.all.completed': '請確保所有表格已經填妥，方可提交。 ',
  'form.confirm.limit.placeholder': '請連接店鋪以估算信用額度',
  'form.confirm.personal-info-collection-statement': '按此閱讀收集個人資料聲明',
  //
  'form.thirdparty._LIANLIANPAY': '連連支付 （LianLian Pay）',
  'form.thirdparty._PINGPONG': 'PingPong',
  'form.thirdparty._PAYONEER': '派安盈 （Payoneer）',
  'form.thirdparty._SKYEE': '收款易 （Skyee）',
  'form.thirdparty._AIRWALLEX': '空中雲匯 （Airwallex）',
  'form.thirdparty._WORLDFIRST': '萬里匯 （WorldFirst）',
  'form.thirdparty._ONERWAY': '萬唯 （OnerWay）',
  'form.thirdparty._SUNRATE': '尋匯 （Sunrate）',
  'form.thirdparty._BEEPAY': 'Beepay',
  'form.thirdparty._HUIFU': '匯付天下',
  'form.thirdparty._ASIALINK': '亞聯',
  'form.thirdparty._CURRENXIE': 'Currenxie',
  'form.thirdparty._IPAYLINKS': 'iPayLinks',
  'form.thirdparty._OFX': 'OFX',
  'form.thirdparty._VCAN': 'Vcan',

  'form.amazon.region.NA': '北美',
  'form.amazon.region.EU': '歐洲',
  'form.amazon.region.FE': '日本 / 澳洲',

  'form.ecomshop.notice': `我們會以貴司月均銷售額的三到四成作為審批額度的基礎。
  如貴司的月均銷售為三十萬美元，我司的額度審批將會以九萬美元至十二萬美元作為基礎。 最終額度會因應貴司的運營表現進行調整。
  本公司知悉及授權橋彼道有限公司獲取及使用本公司在電商平臺上的店鋪資料和數據作分析之用。`,

  'form.credit.nav': 'Credit Related Information',
  'form.credit.partner': 'Partner',
  'form.credit.partnername': 'Partner Name',
  'form.credit.customid': "Applicant's Customer ID in Partner",
  'form.credit.rating': 'Quipital Rating',
  'form.credit.obligor': 'Obligor / Platform Name / POS',
  'form.credit.percentage': 'Obligor Relationship: Qupital Advance Percentage (%)',
  'form.credit.bullet-credit-limit': 'Bullet Credit Limit (USD)',
  'form.credit.instalment-credit-limit': 'Instalment Credit Limit (USD)',
  'form.credit.internallimit': 'Internal Credit Limit',
  'form.credit.internallimitExpiryDate': 'Internal Credit Limit Expiry Date',
  'form.credit.creditrating': 'Seller Credit Rating',
  'form.credit.industry': 'Seller Industry',
  'form.credit.partner.placeholder': 'No Partnership',
  'form.credit.industry.placeholder': 'No Industry',
  'form.credit.maxDebtDuration': 'Max. Debt Duration',
  'form.credit.electronic-document': 'Received clear and complete electronic scanned documents',
  'form.credit.original-document': 'Received original documents',

  'form.sales.nav': 'Sales Related Information',
  'form.sales.partner': 'Partner',
  'form.sales.partnername': 'Partner Name',
  'form.sales.customid': "Applicant's Customer ID in Partner",
  'form.sales.sales': 'Sales',
  'form.sales.salesname': 'Sales Name',
  'form.sales.salescommission': 'Sales Commission Decimal (%)',
  'form.sales.saleexpiry': 'Sales Commission Expiry Date',
  'form.sales.feestructure': 'Fee Schedule',
  'form.sales.obligor': 'Obligor / Platform Name / POS',
  'form.sales.lowestPrice': 'Lowest Price (%)',
  'form.sales.actualPrice': 'Actual Price (%)',
  'form.sales.percentage': 'Obligor Relationship: Funder Fee (%)',
  'form.sales.platformfee': 'Obligor Relationship: Platform Fee Rate (%)',
  'form.sales.credit-enhancement': 'Credit Enhancement',
  'form.sales.feestructure.add': '添加',

  'error.input.array.invalid.format': '格式錯誤',
  'error.input.array.not.greater.than': '請提供最多{{value}}條記錄',
  'error.input.array.not.less.than': '請提供最少{{value}}條記錄',
  'error.input.boolean.invalid.format': '請重新選擇',
  'error.input.date.not.later.than.today': '不可遲於今天',
  'error.input.date.must.be.later.than.today': '不可早於今天',
  'error.input.email.invalid.format': '格式錯誤',
  'error.input.enum.currency.invalid': '格式錯誤',
  'error.input.enum.director-type.invalid': '格式錯誤',
  'error.input.must.be.applicationstage': '格式錯誤',
  'error.input.must.be.atleast': '不可小於{{value}}',
  'error.input.must.be.currency': '格式錯誤',
  'error.input.must.be.date': '格式錯誤',
  //'error.input.must.be.defined': ''
  'error.input.must.be.email': '格式錯誤',
  'error.input.must.be.number': '格式錯誤',
  'error.input.must.be.number.with.decimal': '格式錯誤, 最多{{decimal}}位小數',
  'error.input.must.be.phonenumber': '格式錯誤',
  'error.input.must.be.shareholdertype': '格式錯誤',
  'error.input.must.be.url': '格式錯誤',
  'error.input.must.be.usertype': '格式錯誤',
  'error.input.number.not.less.than': '不可小於{{value}}',
  'error.input.number.not.more.than': '不可大於{{value}}',
  'error.input.string.invalid.format': '格式錯誤',
  'error.input.uuid.invalid.format': '格式錯誤',
  'error.input.number.must.be.positive': '必須為正數',
  'error.input.must.consent': '請同意本項',
  'error.input.shareholder.must.be.sum.100': '股權比例總和應大於70%',
  'error.input.financial.must.have.n.years': '請輸入{{n}}年份財務資料',
  'error.input.credit-limit.must.eq.sum-of-sub-credit-limit': '總信用額度必須等於子信用額度之和',
  'error.ea.input.shareholder.must.be.sum.100': '股權比例總和應大於50%',

  'form.common.must.enter': '必須提供',

});
